import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography, Paper } from '@mui/material';
import React from 'react';
import * as CONFIG from 'shared-scope/config';
import style from 'products/CampaignList/components/message.module.scss';
import fileCheck from 'media/images/file_check.svg';
import { MessageType } from 'products/shared/Campaign/types';
import moment from 'moment-timezone';


export const MESSAGE_STATE_NEW = 0;
export const MESSAGE_STATE_SENT = 1;
export const MESSAGE_STATE_DELIVERED = 2;
export const MESSAGE_STATE_FAILED = 3;

const OUT = 'out';

export type MessageProps = {
  message: MessageType
};

export function Message({ message }: MessageProps) {
  let iconMessageState = 'icon-checkmark-stroke';
  let title = 'New';
  switch (message.state) {
    case MESSAGE_STATE_SENT:
      iconMessageState = 'icon-sent';
      title = 'Sent';
      break;
    case MESSAGE_STATE_DELIVERED:
      iconMessageState = 'icon-sent';
      title = 'Delivered';
      break;
    case MESSAGE_STATE_FAILED:
      iconMessageState = 'icon-alert-filled-circle text-danger';
      title = 'Failed';
      break;
    default:
      break;
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: message.type === OUT ? 'row' : 'row-reverse',
        alignItems: 'center',
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 1,
          ml: message.type === OUT ? 1 : 0,
          mr: message.type === OUT ? 0 : 1,
          backgroundColor: message.type === OUT ? '#FDE7E0 !important' : '#F6F8FA !important',
          borderRadius: message.type === OUT ? '20px 20px 20px 5px' : '20px 20px 5px 20px',
          alignItems: 'center',
          border: 'none',
        }}
        color="primary"
      >
        {message?.attId
          ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'start',
                display: 'flex',
              }}
            >
              <img
                src={`${CONFIG.get('api_host')}${PUBLIC_PATH}api/fs/attachment-file/${message.attId}`}
                style={{
                  maxWidth: '160px',
                  maxHeight: '50px',
                }}
                alt="att"
                loading="eager"
                onError={(event) => {
                  event.currentTarget.src = fileCheck;
                }}
              />
            </Box>
          )
          : null}
        <Stack>
          <Typography variant="caption">{message.message}</Typography>
          <Stack direction="row" justifyContent="space-between">
            {message.type === OUT
              ? <i className={`${style.messageIcon} ${iconMessageState}`} title={title} />
              : null}
            {message.timestamp
              ? (
                <Typography color="text.secondary" variant="subtitle2" fontSize={10}>
                  {moment(message.timestamp).format('HH:mm')}
                </Typography>
              )
              : null}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
