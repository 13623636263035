import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';
import {
 FormField, request, SchedulerLabel, Sources,
} from 'products/shared/Campaign';
import {
  Actions,
  StepGeneral,
  StepQuestions,
  StepScheduler,
  StepSettings,
  StepTarget,
} from 'products/shared/Campaign/steps';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { CountryCapability } from 'graphql-api';
import { Typography } from '@mui/material';
import { GetCampaignsQuery, getSdk } from 'products/shared/Campaign/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import { NewPollRow, getCampaignData } from '.';

const apiCampaign = getSdk(fetcher);
const back = '/poll';

export default function ClonePoll({ newPoll }: { newPoll?: boolean }) {
  const { cloneId = 0 } = useParams();
  const form = useForm<NewPollRow>();
  const navigate = useNavigate();
  const {
    addSuccess,
    addError,
  } = useAlert();

  const {
    isLoading,
    error,
    fetch: createPoll,
    result,
  } = useRequest(request.createCampaign);
  const {
    isLoading: isLoadingCampaign,
    error: errorCampaign,
    fetch: fetchCampaign,
    result: campaigns,
  } = useRequest<GetCampaignsQuery>(apiCampaign.getCampaigns);

  const setInitial = useMemo(() => form.setInitial, [form]);

  const [schedulerOn, setSchedulerOn] = useState(false);

  const onSubmit = useCallback(async (row: NewPollRow) => {
    await createPoll(getCampaignData(row, schedulerOn));
  }, [schedulerOn, createPoll]);

  useEffect(() => {
    error && addError(s('Error creating a new poll'));
    errorCampaign && addError(errorCampaign.getMessage());
  }, [error, errorCampaign, addError]);

  useEffect(() => {
    result && addSuccess(s('New poll added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  useEffect(() => {
    cloneId && fetchCampaign({
      filter: JSON.stringify({ id: { $eq: +cloneId } }),
    });
  }, [fetchCampaign, cloneId]);

  const clonedCampaign = useMemo(
    () => (campaigns?.campaign?.length ? campaigns.campaign[0] : null),
    [campaigns?.campaign],
  );


  /**
   * Set form initial for simple fields
   */
  useEffect(() => {
      setSchedulerOn(clonedCampaign?.scheduleEnabled || false);
      clonedCampaign
        && setInitial({
          title: 'COPY ' + (clonedCampaign.name || ''),
          source_type: newPoll ? Sources.excel : Sources.campaign,
          questions: {
            initialMessage: clonedCampaign.messageTemplates?.[0]?.text || '',
            initialAnswer: clonedCampaign.params.reply_actions?.[0]?.keywords || '',
            initialAttachment: { id: clonedCampaign.attachmentId || null },
            template: clonedCampaign.conversationTemplate,
          },
          // message: {
          //   text: clonedCampaign.messageTemplates?.[0]?.text || '',
          //   attachment: clonedCampaign.attachmentId,
          // },
          schedule_tz: clonedCampaign.scheduleTz || '',
          schedule_times: clonedCampaign.scheduleTimes || '',
        });
  }, [clonedCampaign, newPoll, setInitial]);

  return (
    <Page
      title={s('Clone poll from :name', { name: clonedCampaign?.name })}
      back={back}
      loading={isLoading || isLoadingCampaign}
    >
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral
              titleFieldName={s('Poll name')}
              initial={useMemo(() => (clonedCampaign ? {
                route: clonedCampaign.route || undefined,
                country: clonedCampaign.country || undefined,
              } : undefined), [clonedCampaign])}
            />
          </Step>
          <Step label={s('Target Audience')}>
            <StepTarget
              avail={newPoll ? [Sources.excel, Sources.text, Sources.phonebook] : [Sources.campaign]}
              campaignId={+cloneId}
              targetType="poll"
            />
          </Step>
          <Step label={s('Settings')}>
            <StepSettings
              sourceType={form.getValue(FormField.sourceType)}
              sourceValue={form.getValue(FormField.sourceValue) || null}
            />
          </Step>
          <Step label={s('Compose your message')}>
            <StepQuestions
              sourceType={form.getValue(FormField.sourceType)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={clonedCampaign?.conversationTemplateId?.id || undefined}
            />
          </Step>
          <Step label={<SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler />
              : <Typography variant="subtitle1">{s('Campaign will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <Actions
          submitText={s('Clone poll')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}
