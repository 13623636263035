import axios from 'axios';
import * as CONFIG from 'shared-scope/config';
import Grid from '@mui/material/Grid';
import Drawer from 'shared-scope/components/Drawer';
import { Box, Tab, Tabs } from '@mui/material';
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Campaign } from 'graphql-api';
import { getSdk as campaignGetSdk } from 'products/CampaignList/query.generated';
import { getSdk } from 'shared-scope/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import { Statistic } from 'products/Details/Statistic';
import { Date } from 'products/Details/Date';
import { Messages } from 'products/Details/Messages';
import { Price } from 'products/Details/Price';
import { Header } from 'products/Details/Header';
import { General } from 'products/Details/General';
import { Conversation } from 'products/Details/Poll/Conversation';
import { GroupAnswer } from 'products/Details/Poll/GroupAnswer';
import { RawAnswer } from 'products/Details/Poll/RawAnswer';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { runWithInterval, useMountedState } from '@xeebi/neru';
import { GridType } from 'products/CampaignList/types';
import { hasPermissionById, PERMISSION_GENERAL, PermissionWrapper } from 'permission';
import Loading from 'shared-scope/components/Loading';
import useAlert from 'shared-scope/hooks/useAlert';


const apiCampaign = campaignGetSdk(fetcher);
const apiCommon = getSdk(fetcher);

export type CampaignDetailsProps = {
  gridRef: MutableRefObject<GridApiPro>
  type: GridType
};

type Changes = {
  name: string
  scheduleEnabled: boolean
  scheduleTimes: string
  scheduleTz: string
  addressBookId: number | null
};

const FIELDS = [
    'name',
    'scheduleEnabled',
    'scheduleTimes',
    'scheduleTz',
    'addressBookId',
];

function initFields(campaign: Campaign) {
  return {
    name: campaign?.name || '',
    scheduleEnabled: campaign?.scheduleEnabled || false,
    scheduleTimes: campaign.scheduleTimes,
    scheduleTz: campaign.scheduleTz,
    addressBookId: campaign.addressBookId,
  } as Changes;
}

const saveCampaign = async (changes: Changes, campaign: Campaign) => {
  const params = {
    campaign_name: changes.name,
    schedule_enabled: changes.scheduleEnabled,
    schedule_tz: changes.scheduleTz,
    schedule_times: changes.scheduleTimes,
    params: campaign.params,
    mobius_notify: true,
    address_book_id: changes.addressBookId,
  };
  const { data } = await axios.put(`${CONFIG.get('api')}/campaign/${campaign.id}`, params);

  return {
    id: campaign.id,
    name: data.campaign_name as string,
    scheduleEnabled: data.schedule_enabled as boolean,
    scheduleTz: data.schedule_tz as string,
    scheduleTimes: data.schedule_times as string,
    addressBookId: data.address_book_id,
  } as Changes;
};

const loadCampaign = async (campaignId: number) => {
  const params = {
    filter: JSON.stringify({ id: campaignId }),
    limit: 1,
    offset: 0,
    sort: null,
  };
  const { campaign } = await apiCampaign.getVoteSmsCampaigns(params);
  return campaign[0] as Campaign;
};

const CONVERSATION = 'conversations';
const SETTING = 'settings';
const GROUP_ANSWER = 'results';
const RAW_ANSWER = 'raw-answers';
const MESSAGE = 'messages';

const tabTitle = {
  [CONVERSATION]: 'Conversations',
  [SETTING]: 'Settings',
  [GROUP_ANSWER]: 'Results',
  [RAW_ANSWER]: 'Raw answers',
  [MESSAGE]: 'Messages',
};

export function useDetailsProps() {
  return useOutletContext<CampaignDetailsProps>();
}

export function Details() {
  const { gridRef, type } = useDetailsProps();
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const { addError } = useAlert();
  const isMounted = useMountedState();

  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [saving, setSaving] = useState(false);
  const [changes, setChanges] = useState<Changes | null>(null);
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [routeCount, setRouteCount] = useState(1);

  const hasPermission = hasPermissionById(PERMISSION_GENERAL);
  const path = type === GridType.Campaign
    ? '/campaign'
    : type === GridType.Poll
      ? '/poll'
      : '/opt-in/campaign-list';

  useEffect(() => {
    const loadRoutes = async () => {
      const { routeCount: count } = await apiCommon.getRouteCount();
      setRouteCount(count || 1);
    };
    loadRoutes();
  }, [isMounted]);

  useEffect(() => {
    if (!tab && type === GridType.Poll) {
      navigate(`${path}/${id}/${CONVERSATION}`);
    }
    if (tab && tab === SETTING && type === GridType.Poll && !hasPermission) {
      navigate(`${path}/${id}/${CONVERSATION}`);
    }
    if (!tab && type === GridType.OptIn) {
      navigate(`${path}/${id}/${SETTING}`);
    }
  }, [tab, hasPermission, id, navigate, path, type]);

  useEffect(() => {
    if (!campaign || !changes) {
      return;
    }
    let checkChanges = false;
    for (const field of FIELDS) {
      if (campaign[field as keyof Changes] !== changes[field as keyof Changes]) {
        checkChanges = true;
        break;
      }
    }
    // Validation
    if (checkChanges && changes.name) {
      setHasChanges(checkChanges);
    } else {
      setHasChanges(false);
    }
  }, [changes, campaign]);

  useEffect(() => {
    if (campaign) {
      setChanges(initFields(campaign));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign?.name, campaign?.scheduleTz, campaign?.scheduleEnabled, campaign?.scheduleTimes]);

  useEffect(() => {
    const load = async () => {
      if (id && refresh) {
        try {
          const result = await loadCampaign(Number(id));
          if (result) {
            setCampaign(result);
            gridRef.current.updateRows([result]);
          } else {
            setCampaign(null);
            navigate(path);
          }
        } catch (e: any) {
          console.log(e);
          const msg = e?.message || '';
          msg && addError(msg);
        } finally {
          setRefresh(false);
        }
      }
    };
    load();
  }, [id, refresh, type, gridRef, navigate, path, addError]);

  useEffect(() => {
    const saveChanges = async () => {
      if (saving && changes && campaign) {
        setLoading(true);
        try {
          const result = await saveCampaign(changes, campaign);
          setCampaign({
            ...campaign,
            ...result,
          });
          gridRef.current.updateRows([result]);
        } catch (e: any) {
          console.log(e);
          const msg = e?.message || '';
          msg && addError(msg);
        } finally {
          setSaving(false);
          setLoading(false);
        }
      }
    };
    saveChanges();
    // eslint-disable-next-line
  }, [saving, changes, campaign]);

  // Auto refresh
  useEffect(() => {
    const runner = runWithInterval(() => setRefresh(true), 5_000);
    return runner.stop;
  }, []);

  const tabComponent: any = useMemo(() => {
    if (!campaign || !changes) {
      return null;
    }
    switch (tab) {
      case CONVERSATION:
        return (
          <Conversation data={campaign} />
        );
      case GROUP_ANSWER:
        return (
          <GroupAnswer data={campaign} />
        );
      case RAW_ANSWER:
        return (
          <RawAnswer data={campaign} />
        );
      case MESSAGE:
        return (
          <Messages data={campaign} />
        );
      default:
        return (
          <General
            campaign={campaign}
            type={type}
            name={changes.name}
            onName={(name: string) => setChanges({ ...changes, name })}
            phoneBookId={changes.addressBookId}
            onPhoneBookId={(addressBookId) => setChanges({ ...changes, addressBookId })}
            scheduleEnabled={changes.scheduleEnabled}
            onScheduleEnabled={(se: boolean) => {
              setChanges({ ...changes, scheduleEnabled: se });
            }}
            scheduleTimes={changes.scheduleTimes}
            onScheduleTimes={(st: string) => setChanges({ ...changes, scheduleTimes: st })}
            tz={changes.scheduleTz}
            onTz={(tz: string) => setChanges({ ...changes, scheduleTz: tz })}
            showRoute={routeCount > 1}
          />
        );
    }
  }, [tab, changes, campaign, type, routeCount]);

  return (
    <Drawer
      open={!!id}
      loading={loading}
      disabled={!hasChanges || !hasPermission}
      onClose={() => navigate(path)}
      onSave={() => hasChanges && setSaving(true)}
    >
      {!campaign
        ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Loading size={150} />
          </Box>
        )
        : (
          <Grid
            container
            spacing={1}
            direction="column"
          >
            {/* Header */}
            <Grid item>
              <Header
                campaign={campaign}
                gridRef={gridRef}
                onError={addError}
                campaignType={type}
                onChange={(updates) => {
                  setCampaign({
                    ...campaign,
                    ...updates,
                  });
                }}
              />
            </Grid>

            {/* Info */}
            <PermissionWrapper scopes={[PERMISSION_GENERAL]}>
              <Grid
                item
                container
                direction="row"
                spacing={1}
                alignItems="stretch"
              >
                <Grid item md={5}>
                  <Statistic data={campaign} />
                </Grid>

                <Grid item md={3}>
                  <Date data={campaign} />
                </Grid>

                <Grid item md={4} sx={{ position: 'relative' }}>
                  <Price data={campaign} type={type} />
                </Grid>
              </Grid>
            </PermissionWrapper>


            {/* General */}
            {((type === GridType.Poll || type === GridType.OptIn) && tab)
              ? (
                <Grid item>
                  <Tabs
                    variant="scrollable"
                    scrollButtons={false}
                    value={tab}
                    onChange={(e, t: string) => navigate(`${path}/${id}/${t}`)}
                    orientation="horizontal"
                    sx={{ marginLeft: '16px', marginBottom: '-8px' }}
                  >

                    {type === GridType.OptIn
                      ? <Tab label={tabTitle[MESSAGE]} value={MESSAGE} />
                      : null}

                    {type !== GridType.OptIn
                      ? <Tab label={tabTitle[CONVERSATION]} value={CONVERSATION} />
                      : null}

                    {hasPermission
                      ? <Tab label={tabTitle[SETTING]} value={SETTING} />
                      : null}

                    {type !== GridType.OptIn
                      ? <Tab label={tabTitle[GROUP_ANSWER]} value={GROUP_ANSWER} />
                      : null}

                    {type !== GridType.OptIn
                      ? <Tab label={tabTitle[RAW_ANSWER]} value={RAW_ANSWER} />
                      : null}
                  </Tabs>
                </Grid>
              )
              : null}

            <Grid item width="100%">
              { tabComponent }
            </Grid>
          </Grid>
        )}
    </Drawer>
  );
}

