import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import TemplateType from 'products/Templates/types';
import { s } from 'i18n';
import React, {
 useMemo, useState,
} from 'react';
import { FormController } from 'shared-scope/components/Form';
import { Maybe } from 'shared-scope/types';
import { QuestionsTemplate } from 'products/common/types';
import useAttachments from 'shared-scope/hooks/useAttachments';
import { FormField, QuestionValue } from '../types';
import { OptInQuestions, Question, Questions } from '../components';
import {
  getMessageError, isCorrectMessage, isCorrectOptIn, isCorrectQuestions,
} from '../helpers';

export default function StepTemplate() {
  const { attachmentList } = useAttachments();

  const [templateType, setTemplateType] = useState<TemplateType>(TemplateType.Text);

  const messageRule = useMemo(
    () => isCorrectMessage(attachmentList, undefined, { answer: false }),
    [attachmentList],
  );
  const questionsRule = useMemo(() => isCorrectQuestions(attachmentList), [attachmentList]);
  const optInRule = useMemo(() => isCorrectOptIn(attachmentList), [attachmentList]);

  return <Stack spacing={2}>
    <ToggleButtonGroup
      value={templateType}
      onChange={(e, v) => {
        v !== null && setTemplateType(v);
      }}
      exclusive
      aria-label="template-type"
    >
      <ToggleButton value={TemplateType.Poll}>{s('Poll')}</ToggleButton>
      <ToggleButton value={TemplateType.Text}>{s('Text')}</ToggleButton>
      <ToggleButton value={TemplateType.OptIn}>{s('Opt-in')}</ToggleButton>
    </ToggleButtonGroup>

    {templateType === TemplateType.Text
      && <FormController<QuestionValue>
        name={FormField.message}
        validateRule={messageRule}
      >
        {(ctrl) => (
          <Question
            title={s('Message')}
            question={ctrl.value?.text || ''}
            onChange={(v) => ctrl.onChange({
                text: v,
                attachment: ctrl.value?.attachment,
              })}
            onBlur={() => ctrl.validate()}
            attachment={ctrl.value?.attachment}
            onAttachmentChange={(v) => ctrl.onChange({
                text: ctrl.value?.text || '',
                attachment: v,
              })}
            error={ctrl.fullError ? getMessageError(ctrl.fullError) : undefined}
          />
        )}
      </FormController>}

    {templateType === TemplateType.Poll
      && <FormController<Maybe<QuestionsTemplate>>
        name={FormField.questions}
        validateRule={questionsRule}
      >
        {(ctrl) => (
          <Questions
            value={ctrl.value}
            onChange={(v) => ctrl.onChange(v)}
            error={ctrl.fullError}
          />
        )}
      </FormController>}

    {templateType === TemplateType.OptIn
      && <FormController<Maybe<QuestionsTemplate>>
        name={FormField.optIn}
        validateRule={optInRule}
      >
        {(ctrl) => (
          <OptInQuestions
            value={ctrl.value || null}
            onChange={(v) => ctrl.onChange(v)}
            error={ctrl.fullError}
          />
        )}
      </FormController>}
  </Stack>;
}

export type StepTemplateRow = {
  message?: QuestionValue
  questions?: QuestionsTemplate
  optIn?: QuestionsTemplate
};
