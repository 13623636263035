import Page from 'layout/Page';
import { s } from 'i18n';
import {
  Button,
  Box,
  Stack,
  Autocomplete,
  List,
  ListItem,
  ListItemButton,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { getSdk } from 'products/PhoneBook/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Region, PhoneBook as PhoneBookObj, PhoneBookFolder } from 'src/graphql-api';
import PhoneBookGrid from 'products/PhoneBook/PhoneBookGrid';
import { useNavigate, useLocation } from 'react-router-dom';
import { ConfirmDlg } from 'shared-scope/components/ConfirmDlg';
import { useLocalStorage } from '@xeebi/neru';
import useAlert from 'shared-scope/hooks/useAlert';
import { PhoneBookSettings } from 'products/PhoneBook/helpers';
import { MangoFilter } from 'shared-scope/types';
import { USA } from 'shared-scope/const';


const api = getSdk(fetcher);

export default function PhoneBook() {
  const navigate = useNavigate();
  const location = useLocation();
  const { addError } = useAlert();
  const [folders, setFolders] = useState<PhoneBookFolder[]>([]);
  const [phoneBooks, setPhoneBooks] = useState<PhoneBookObj[]>([]);
  const [folderToDelete, setFolderToDelete] = useState<PhoneBookFolder | null>(null);
  const [phoneBookToDelete, setPhoneBookToDelete] = useState<PhoneBookObj | null>(null);
  const [regions, setRegions] = useState<Region[]>([]);
  const [settings, setSettings] = useLocalStorage<PhoneBookSettings>('PhoneBookStorage', { folder: null, phoneBookFilter: '', phoneBook: null });

  const [phoneBookFilter, setPhoneBookFilter] = useState(settings.phoneBookFilter);
  const [folder, setFolder] = useState<PhoneBookFolder | null>(settings.folder || null);
  const [phoneBook, setPhoneBook] = useState<PhoneBookObj | null>(settings.phoneBook || null);
  const inputDelay = useRef<NodeJS.Timeout>();

  const loadRegions = async () => {
    try {
      const { region } = await api.getRegion({
        filter: `{"country.id": ${USA}}`,
        sort: '[{"code": 1}]',
      });
      //region.forEach((r) => {
      //  r.name = r.code + ' ' + r.name;
      //});
      setRegions(region);
    } catch (e) {
      console.log(e);
      addError(s('Failed to loads regions'));
      setRegions([]);
    }
  };

  const loadFolders = async () => {
    try {
      setFolders((await api.getPhoneBookFolder()).phoneBookFolder);
    } catch (e) {
      console.log(e);
      addError(s('Failed to loads folders'));
      setFolders([]);
    }
  };

  const loadPhoneBooks = async () => {
    try {
      const filters: MangoFilter = [];
      filters.push({ 'folder.id': folder?.id || null });
      if (phoneBookFilter) {
        filters.push({ name: { $like: '%' + phoneBookFilter + '%' } });
      }
      const filter: any = filters.length === 1 ? filters[0] : { $and: filters };
      const { phoneBook: data } = await api.getPhoneBook({
          filter: JSON.stringify(filter),
          sort: '[{"name": 1}]',
        });
      setPhoneBooks(data);
      let bookFound = false;
      for (const b of data) {
        if (b.id === phoneBook?.id) {
          bookFound = true;
          break;
        }
      }
      if (!bookFound) {
        setPhoneBook(null);
      }
    } catch (e) {
      console.log(e);
      addError(s('Failed to loads phone books'));
      setPhoneBooks([]);
    }
  };

  const cleanUp = useCallback(() => {
    setSettings({
      folder: null,
      phoneBookFilter: '',
      phoneBook: null,
    });
    setPhoneBook(null);
    setPhoneBookFilter('');
    setFolder(null);
  }, [setSettings]);

  const deleteFolder = async () => {
    if (folderToDelete) {
      try {
        await api.phoneBookFolderDelete({
          filter: JSON.stringify({ id: folderToDelete.id }),
        });
        if (folderToDelete.id === folder?.id) {
          cleanUp();
        }
        loadFolders();
        setFolderToDelete(null);
      } catch (e) {
        console.log(e);
        setFolderToDelete(null);
        addError(s('Failed to delete folder') + ' ' + folderToDelete.name);
      }
    }
  };

  const deletePhoneBook = async () => {
    if (phoneBookToDelete) {
      try {
        await api.phoneBookDelete({
          filter: JSON.stringify({ id: phoneBookToDelete.id }),
        });
        loadPhoneBooks();
        setPhoneBookToDelete(null);
        setPhoneBook(null);
      } catch (e) {
        console.log(e);
        setPhoneBookToDelete(null);
        addError(s('Failed to delete phone book') + ' ' + phoneBookToDelete.name);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === '/phone-book') {
      loadFolders();
      loadRegions();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    loadPhoneBooks();
    // eslint-disable-next-line
  }, [folder]);

  useEffect(() => {
    setSettings({
      folder,
      phoneBookFilter,
      phoneBook,
    });
  }, [folder, phoneBook, phoneBookFilter, setSettings]);

  useEffect(() => {
    clearTimeout(inputDelay.current);
    inputDelay.current = setTimeout(() => {
      loadPhoneBooks();
    }, 500);
  }, [phoneBookFilter]);

  return (
    <Page
      title={s('Phone books')}
      headTools={[
        <Button
          key="create"
          variant="contained"
          onClick={() => navigate('/phone-book/new-phone-book')}
        >
          {s('Add new phone book')}
        </Button>,
        <Button
          key="create-folder"
          variant="outlined"
          onClick={() => navigate('/phone-book/new-folder')}
        >
          {s('Create folder')}
        </Button>,
      ]}
    >
      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid
          item
          md={3}
        >
          <Box
            sx={{
              backgroundColor: '#F2F2F6',
              p: 2,
              height: '100%',
              borderRadius: 4,
            }}
          >
            <Stack spacing={2}>
              <Autocomplete
                value={folder}
                options={folders}
                getOptionLabel={(option) => option.name || ''}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id} sx={{ width: '100%', display: 'flex' }}>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                          <Typography>{option?.name}</Typography>
                          <Stack spacing={2} direction="row" height="7px" alignItems="center">
                            <IconButton
                              aria-label="Edit folder"
                              color="primary"
                              sx={{ borderRadius: '50' }}
                              onClick={() => {
                                cleanUp();
                                navigate(`/phone-book/edit-folder/${option?.id}`);
                              }}
                            >
                              <div><i className="icon-edit" /></div>
                            </IconButton>
                            <IconButton
                              aria-label="Delete folder"
                              color="primary"
                              sx={{ borderRadius: '50' }}
                              onClick={() => {
                                setFolderToDelete(option);
                              }}
                            >
                              <div><i className="icon-delete" /></div>
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Folder"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, value) => setFolder(value)}
              />
              <TextField
                value={phoneBookFilter}
                placeholder="Name"
                onChange={(event) => setPhoneBookFilter(event.target.value)}
              />
              <List
                sx={{
                  backgroundColor: 'white',
                  maxHeight: '600px',
                  borderRadius: 4,
                  overflow: 'auto',
                }}
              >
                {phoneBooks.map((pb) => {
                  return (
                    <ListItemButton
                      selected={pb.id === phoneBook?.id}
                      key={pb.id}
                      onClick={() => setPhoneBook(pb)}
                    >
                      <ListItem>{pb.name}</ListItem>
                    </ListItemButton>
                  );
                })}
              </List>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          md={9}
        >
          {
            phoneBook ? (
              <PhoneBookGrid
                regions={regions}
                phoneBook={phoneBook}
                onPhoneBookDelete={() => setPhoneBookToDelete(phoneBook)}
              />
            ) : (
              <Stack direction="row" justifyContent="center" height="100%">
                <Stack justifyContent="center" height="100%">
                  <Typography fontSize="18px" fontWeight="500" color="#808B95">{s('Select phone book')}</Typography>
                </Stack>
              </Stack>
            )
          }
        </Grid>
      </Grid>
      {
        folderToDelete == null
        ? null
        : <ConfirmDlg
            disabled={false}
            confirmParams={{
                content: <div>Are you sure you want to delete phone book folder <b>{folderToDelete.name}</b>?</div>,
                show: true,
                onOk: () => deleteFolder(),
                onClose: () => setFolderToDelete(null),
            }}
            title={s('Are you sure?')}
            maxWidth="md"
            fullWidth
        />
      }
      {
        phoneBookToDelete == null
        ? null
        : <ConfirmDlg
            disabled={false}
            confirmParams={{
                content: <div>{s('Are you sure you want to delete phone book')} <b>{phoneBookToDelete.name}</b>?</div>,
                show: true,
                onOk: () => deletePhoneBook(),
                onClose: () => setPhoneBookToDelete(null),
            }}
            title={s('Are you sure?')}
            maxWidth="md"
            fullWidth
        />
      }
    </Page>
  );
}
