import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { s } from 'i18n';


export default function Struct() {
  const columns: GridColDef[] = [
    {
      field: 'phoneNumber',
      headerName: s('Phone'),
      type: 'string',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
      preProcessEditCellProps: (params) => {
        let pn = 0;
        try {
          pn = Number(params.props.value);
        } catch (e) {
          return { ...params.props, error: true };
        }
        return { ...params.props, error: !(pn > 12_000_000_000 && pn < 20_000_000_000) };
      },
    },
    {
      field: 'firstName',
      headerName: s('First name'),
      type: 'string',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: s('Last name'),
      type: 'string',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'gender',
      headerName: s('Gender'),
      disableColumnMenu: true,
      type: 'string',
      filterable: true,
      minWidth: 200,
      flex: 1,
      editable: true,
    },
    {
      field: 'state',
      headerName: s('State'),
      disableColumnMenu: true,
      type: 'string',
      filterable: true,
      minWidth: 200,
      flex: 1,
      editable: true,
    },
    {
      field: 'tz',
      headerName: s('Time zone'),
      disableColumnMenu: true,
      type: 'string',
      filterable: true,
      minWidth: 120,
      flex: 1,
      editable: true,
    },
  ];
  return columns;
}
