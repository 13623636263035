import axios, { AxiosRequestConfig } from 'axios';
import * as CONFIG from 'shared-scope/config';
import { forOwn } from 'lodash';

export const createCampaign = async (
  data: Record<string, any>,
  config?: AxiosRequestConfig,
) => axios.post(
  `${CONFIG.get('api')}/campaign/import`,
  data,
  config,
);

export const importContacts = async (
  data: Record<string, any>,
  config?: AxiosRequestConfig,
) => {
  if (!data.phone_book_id) {
    throw new Error('Incorrect Phone book');
  }
  return axios.post(
    `${CONFIG.get('api')}/phone-book/${data.phone_book_id}/import`,
    data,
    config,
  );
};

export function uploadSource(data: UploadData, config?: object) {
  const formData = new FormData();
  forOwn(data, (v, k) => v && formData.append(k, v));
  return axios.post(
    `${CONFIG.get('api')}/import/upload`,
    formData,
    config || {},
  );
}

type UploadData = {
  source_type: string
  text?: string
  file?: File
  phone_book_id?: string
  phone_book_filter?: string
  campaign_id?: string
  campaign_filter?: string
};
