import { s } from 'i18n';
import moment from 'moment-timezone';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import React, {
} from 'react';
import { Campaign } from 'graphql-api';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';


export function Date({ data }: DateProps) {
  const startDate = data?.startTs ? moment(data.startTs).format('YYYY-MM-DD') : '';
  const startTime = data?.startTs ? moment(data.startTs).format('HH:mm:ss') : '';
  const stopDate = data?.stopTs ? moment(data.stopTs).format('YYYY-MM-DD') : '';
  const stopTime = data?.stopTs ? moment(data.stopTs).format('HH:mm:ss') : '';
  return (
    <WhiteBox sx={{ height: '100%' }}>
      <Stack
        spacing={1}
        direction="column"
        sx={{ height: '100%' }}
      >
        <Typography variant="h3">{s('Date')}</Typography>
        <Stack direction="row" justifyContent="space-between" flexGrow={1} alignItems="center">
          <Stack spacing={2}>
            <Typography color="text.secondary">{s('Start')}</Typography>
            <Stack spacing={1}>
              <Typography>{startDate}</Typography>
              <Typography color="text.secondary">{startTime}</Typography>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography color="text.secondary">{s('Stop')}</Typography>
            <Stack spacing={1}>
              <Typography>{stopDate}</Typography>
              <Typography color="text.secondary">{stopTime}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </WhiteBox>
  );
}

export type DateProps = {
  data: Campaign | null
};
