import React from 'react';
import { Stack, Typography } from '@mui/material';
import { s } from 'i18n';
import {
  Campaign as CampaignType,
  CampaignStatus,
} from 'src/graphql-api';
import { CampaignETA, GridType } from 'products/CampaignList/types';
import { cloneDeep, isEqual, sum } from 'lodash';
import { countETA, getCampaignStatus, getStatusStyle } from 'products/CampaignList/helpers';
import campaignStyles from 'products/CampaignList/campaign.module.scss';
import { CAMPAIGN_TYPE_DRIP } from 'products/common/helpers';


export type StatusBadgeProps = {
  row: CampaignType
  value: CampaignStatus
  gridType: GridType
  hideCounters?: boolean
};

const oldCampaigns: { [k: number]: CampaignETA } = {};

export function StatusBadge({
  row,
  value,
  gridType,
  hideCounters = false,
}: StatusBadgeProps) {
  const {
    id: campaignId,
    statusString = '',
    status = null,
    dlrBilling,
    type,
  } = row as CampaignETA;
  const messagesTotal = row.messagesTotal || 0;
  const messagesFailedTotal = row.messagesFailedTotal || 0;
  const messagesSentTotal = row.messagesSentTotal || 0;
  const messagesStopList = row.messagesStopList || 0;
  const messagesRestrictedContent = row.messagesRestrictedContent || 0;
  const messagesFailedUnroutable = row.messagesFailedUnroutable || 0;
  const messagesFailedInvalidNumber = row.messagesFailedInvalidNumber || 0;
  const messagesFailedFiltered = row.messagesFailedFiltered || 0;
  const dsDelivered = row.dsDelivered || 0;
  const dsFiltered = row.dsFiltered || 0;
  const dsErrors = row.dsErrors || 0;
  let eta: string | undefined;

  if (campaignId && !isEqual(row, oldCampaigns[campaignId])) {
    oldCampaigns[campaignId] = countETA(cloneDeep(row), oldCampaigns[campaignId]);
    eta = oldCampaigns[campaignId].eta;
  }

  const cls = getStatusStyle(status, statusString);
  const st = getCampaignStatus(status?.id, gridType);

  let statusLabel = st ? s(st.name) : value.name;
  statusLabel += statusString ? ' (' + s(statusString) + ')' : '';

  const badge = (
    <div className={`${cls} me-2 ${campaignStyles.badge}`}>
      {statusLabel}
    </div>
  );
  const etaSpan = (
    <span className={`badge bg-light text-dark me-2 ${campaignStyles.badge}`}>
      {s('ETA')}:{eta || s('n/a')}
    </span>
  );

  const drip = type === CAMPAIGN_TYPE_DRIP
    ? (
      <span className="badge bg-light text-dark me-2">
        Drip
      </span>
    )
    : null;

  // Errors
  const errorCount = sum([
    messagesFailedTotal,
    messagesStopList,
    messagesRestrictedContent,
    messagesFailedUnroutable,
    messagesFailedInvalidNumber,
    messagesFailedFiltered,
    dlrBilling ? dsErrors + dsFiltered : 0,
  ]);
  const successCount = dlrBilling ? dsDelivered : messagesSentTotal;
  const successStyle = (dlrBilling ? campaignStyles.textDelivered : 'text-success') + ' fw-bold';

  return (
    <Stack spacing={0} alignItems="baseline" justifyContent="center" sx={{ height: '100%' }}>
      <Stack direction="row">{ badge }{ eta ? etaSpan : '' }{drip}</Stack>
      {!hideCounters
        ? (
          <Stack direction="row" spacing={1}>
            <Typography fontSize={12}><b>{messagesTotal ? ((100.0 * (successCount + errorCount)) / messagesTotal).toFixed(1) : 0}% </b></Typography>
            <Typography fontSize={12}>
              <span className={successStyle}>
                {successCount.shortFormNumberStr()}
              </span>
              /
              <span className="text-black">
                {messagesTotal.shortFormNumberStr()}
              </span>
              {errorCount
                ? (
                  <span>/
                    <span className="text-danger">
                      {(errorCount).shortFormNumberStr()}
                    </span>
                  </span>
                )
                : null}
            </Typography>
          </Stack>
        )
        : null}
    </Stack>
  );
}
