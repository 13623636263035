import {
  Box, Grid, Stack, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { s } from 'i18n';
import { PieChart as MuiPieChart } from '@mui/x-charts/PieChart';


export default function PieChart({
    data,
    height,
}: PieChartProps) {
  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    setActive(data.reduce((acc, row) => acc + row.value, 0));
  }, [data]);

  return (
    <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" flexGrow={1}>
      <Stack spacing={1} sx={{ width: '100%' }}>
        {data.map((row) => {
          return (
            <Grid
              container
              key={row.id}
              direction="row"
              columnSpacing={1}
              alignItems="center"
            >
              <Grid item xs={1}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: row.color,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography id={row.label} fontSize={14} fontWeight={400} variant="h5">{`${((row.value / active) * 100).toFixed(0)}%`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontSize={14} fontWeight={600} variant="subtitle1">{row.value.toLocaleString('en-US')}</Typography>
              </Grid>
              <Grid item xs>
                <Typography id={s(row.label)} fontSize={14} fontWeight={400} variant="h5">{s(row.label)}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Stack>
      <MuiPieChart
        series={[
          {
            data,
            startAngle: -270,
            endAngle: 90,
            outerRadius: (height / 2) - 5,
            cx: height / 2,
            cy: height / 2,
          },
        ]}
        slotProps={{
          legend: {
            hidden: true,
          },
          noDataOverlay: {
            message: 'No data',
          },
        }}
        height={height}
      />
    </Stack>
  );
}

export type PieValue = {
  id: any
  value: number
  label: string
  color: string
};

type PieChartProps = {
  height: number
  data: PieValue[]
};
