import { useLocalStorage, useRequest } from '@xeebi/neru';
import { useCallback, useEffect } from 'react';
import { Attachment } from 'graphql-api';
import getAttachments from 'shared-scope/helpers/getAttachments';

const useAttachments = (): {
  attachmentList: Attachment[]
  refreshAttachments: () => void
} => {
  const { fetch: fetchAtt } = useRequest<Attachment[]>(getAttachments);
  const [attachmentList, setAttachmentList] = useLocalStorage<Attachment[]>('AttachmentsList', []);

  const getList = useCallback(async () => {
    const list = await fetchAtt();
    setAttachmentList(list || []);
  }, [fetchAtt, setAttachmentList]);

  const refreshAttachments = useCallback(async () => {
    getAttachments.cache.clear?.();
    await getList();
  }, [getList]);

  useEffect(() => {
    getList();
  }, [getList]);

  return {
    attachmentList,
    refreshAttachments,
  };
};

export default useAttachments;
