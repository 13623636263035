import { s } from 'i18n';
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  Box, Button, Grid, Stack, Typography, useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DataGrid from 'products/CampaignList/Grid';
import { GridType, CampaignStatus } from 'products/CampaignList/types';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import Charts from 'products/Poll/components/Charts';
import { SurveyStatus as PollStatus } from 'src/graphql-api';
import Page from 'layout/Page';
import { getSdk } from 'products/Poll/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import { PermissionWrapper, PERMISSION_GENERAL, hasPermissionById } from 'permission';
import PieChart, { PieValue } from 'shared-scope/components/PieChart';


const api = getSdk(fetcher);

const STATUS_COLORS = {
  [CampaignStatus.Stopped]: '#0099A7',
  [CampaignStatus.Starting]: '#01A76F',
  [CampaignStatus.Running]: '#01A76F',
  [CampaignStatus.Scheduled]: '#7200B1',
  [CampaignStatus.Stopping]: '#0099A7',
  [CampaignStatus.Complete]: '#7C788A',
  [CampaignStatus.Archived]: '#F6FAFF',
  [CampaignStatus.Suspended]: '#D6DADF',
  [CampaignStatus.Cancelling]: '#FF0D2A',
  [CampaignStatus.Cancelled]: '#FF0D2A',
  [CampaignStatus.WaitingForApproval]: '#FCB22A',
  [CampaignStatus.Declined]: '#FF0D2A',
};

export default function Poll() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [showStat, setShowStat] = useState(hasPermissionById(PERMISSION_GENERAL));
  const [pieData, setPieData] = useState<PieValue[]>([]);
  const [active, setActive] = useState<number>(0);
  const [attentions, setAttentions] = useState(0);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const loadPollStatuses = async () => {
      let totalAttentions = 0;
      let totalRunning = 0;
      let pollStatuses: PollStatus[] = [];
      const runningStatuses = [CampaignStatus.Running, CampaignStatus.Scheduled];
      try {
        timeout.current && clearTimeout(timeout.current as NodeJS.Timeout);
        pollStatuses = (await api.getPollStatus()).surveyStatus;
        for (const pollStatus of pollStatuses) {
          totalAttentions += pollStatus.attentions;
          for (const st of runningStatuses) {
            if (st === pollStatus.status) {
              totalRunning += pollStatus.count;
              break;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      timeout.current = setTimeout(loadPollStatuses, totalRunning > 0 || totalAttentions > 0 ? 10000 : 60000);
      setAttentions(totalAttentions);
      const activeStatuses = pollStatuses.filter((ps) => ps.status !== CampaignStatus.Archived && ps.status !== CampaignStatus.Complete);
      setPieData(activeStatuses.map((as) => {
        return {
          id: as.status,
          value: as.count,
          label: as.details,
          color: STATUS_COLORS[as.status as keyof typeof STATUS_COLORS] || '#000000',
        } as PieValue;
      }));
      setActive(activeStatuses.reduce((acc, ps) => acc + ps.count, 0));
    };

    loadPollStatuses();
    return () => { timeout.current && clearTimeout(timeout.current as NodeJS.Timeout); };
  }, []);

  const head = (
    showStat
      ? (
        <Grid container spacing={2} sx={{ mt: 2 }} alignItems="stretch" justifyContent="stretch">
          <Grid item sm={12} md={12} lg={4}>
            <WhiteBox sx={{ height: '100%' }}>
              <Stack spacing={1} sx={{ height: '100%' }} justifyContent="space-between">
                <Stack spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h3">{s('Current status')}</Typography>
                    <Typography fontSize={14} fontWeight={400} variant="subtitle1">{`Active ${active}`}</Typography>
                  </Stack>

                  <PieChart height={150} data={pieData} />

                </Stack>
                <Status
                  label={s('Conversations needing attention')}
                  value={attentions}
                  color={theme.palette.normal.main}
                />
              </Stack>
            </WhiteBox>
          </Grid>
          <Grid item sm={12} md={12} lg={8}>
            <WhiteBox minHeight="300px">
              <Charts />
            </WhiteBox>
          </Grid>
        </Grid>
      ) : null
  );

  return (
    <Page
      title={s('Polls')}
      headTools={[
        <PermissionWrapper key="buttons" scopes={[PERMISSION_GENERAL]}>
          <Button key="stat" variant="text" onClick={() => { setShowStat(!showStat); }} sx={{ color: 'white' }}>
            {showStat ? s('Hide statistics') : s('Show statistics')}
          </Button>
          <Button key="create" variant="contained" onClick={() => navigate('/poll/new')}>
            {s('New poll')}
          </Button>
        </PermissionWrapper>,
      ]}
      renderHead={head}
    >
      <DataGrid gridType={GridType.Poll} />
    </Page>
  );
}

function Status({ label, value, color = '#000' }: StatusProps) {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        borderBottom: `2px solid ${color}`,
        color,
        padding: '8px 12px',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '130%',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Box>{label}</Box>
        <Box>{value}</Box>
      </Stack>
    </Box>
  );
}

type StatusProps = {
  label: string
  value: number
  color?: string
};
