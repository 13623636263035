import * as Types from '../../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetRegionQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetRegionQuery = { __typename?: 'Query', region: Array<{ __typename?: 'Region', name?: string | null, code?: string | null, tz?: string | null }> };

export type GetPhoneBookFolderQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetPhoneBookFolderQuery = { __typename?: 'Query', phoneBookFolder: Array<{ __typename?: 'PhoneBookFolder', id?: number | null, name?: string | null }> };

export type GetPhoneBookQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetPhoneBookQuery = { __typename?: 'Query', phoneBook: Array<{ __typename?: 'PhoneBook', id?: number | null, name?: string | null, folder?: { __typename?: 'PhoneBookFolder', id?: number | null } | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null }> };

export type GetCampaignsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCampaignsQuery = { __typename?: 'Query', campaignCount?: number | null, campaign: Array<{ __typename?: 'Campaign', type?: number | null, id?: number | null, name?: string | null, messageTemplates?: any | null, params?: any | null, conversationTemplate?: any | null, scheduleEnabled?: boolean | null, scheduleTz?: string | null, scheduleTimes?: string | null, attachmentId?: string | null, conversationTemplateId?: { __typename?: 'ConversationTemplate', name?: string | null, id?: number | null } | null, route?: { __typename?: 'Route', id?: number | null, name?: string | null } | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null }> };

export type GetCountryCapabilityQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCountryCapabilityQuery = { __typename?: 'Query', countryCapabilityCount?: number | null, countryCapability: Array<{ __typename?: 'CountryCapability', id?: number | null, maxAttachmentSize?: number | null, termsConditions?: boolean | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null, route?: { __typename?: 'Route', id?: number | null, name?: string | null } | null }> };


export const GetRegionDocument = gql`
    query getRegion($filter: String = null, $sort: String = null, $offset: Int = null, $limit: Int = 0) {
  region(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    name
    code
    tz
  }
}
    `;
export const GetPhoneBookFolderDocument = gql`
    query getPhoneBookFolder($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  phoneBookFolder(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
  }
}
    `;
export const GetPhoneBookDocument = gql`
    query getPhoneBook($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  phoneBook(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
    folder {
      id
    }
    country {
      id
      name
    }
  }
}
    `;
export const GetCampaignsDocument = gql`
    query getCampaigns($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  campaign(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    type
    id
    name
    messageTemplates
    params
    conversationTemplate
    conversationTemplateId {
      name
      id
    }
    scheduleEnabled
    scheduleTz
    scheduleTimes
    attachmentId
    route {
      id
      name
    }
    country {
      id
      name
    }
  }
  campaignCount(filter: $filter)
}
    `;
export const GetCountryCapabilityDocument = gql`
    query getCountryCapability($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = null) {
  countryCapability(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    id
    maxAttachmentSize
    termsConditions
    country {
      id
      name
    }
    route {
      id
      name
    }
  }
  countryCapabilityCount(filter: $filter)
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getRegion(variables?: GetRegionQueryVariables, options?: C): Promise<GetRegionQuery> {
      return requester<GetRegionQuery, GetRegionQueryVariables>(GetRegionDocument, variables, options) as Promise<GetRegionQuery>;
    },
    getPhoneBookFolder(variables?: GetPhoneBookFolderQueryVariables, options?: C): Promise<GetPhoneBookFolderQuery> {
      return requester<GetPhoneBookFolderQuery, GetPhoneBookFolderQueryVariables>(GetPhoneBookFolderDocument, variables, options) as Promise<GetPhoneBookFolderQuery>;
    },
    getPhoneBook(variables?: GetPhoneBookQueryVariables, options?: C): Promise<GetPhoneBookQuery> {
      return requester<GetPhoneBookQuery, GetPhoneBookQueryVariables>(GetPhoneBookDocument, variables, options) as Promise<GetPhoneBookQuery>;
    },
    getCampaigns(variables?: GetCampaignsQueryVariables, options?: C): Promise<GetCampaignsQuery> {
      return requester<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, variables, options) as Promise<GetCampaignsQuery>;
    },
    getCountryCapability(variables?: GetCountryCapabilityQueryVariables, options?: C): Promise<GetCountryCapabilityQuery> {
      return requester<GetCountryCapabilityQuery, GetCountryCapabilityQueryVariables>(GetCountryCapabilityDocument, variables, options) as Promise<GetCountryCapabilityQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;