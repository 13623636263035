import { s } from 'i18n';
import {
  Button,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { fetcher } from 'graphql-api/fetcher';
import React, {
  useEffect,
  useState,
} from 'react';
import reportStar from 'media/images/report_star.svg';
import reportCorner1 from 'media/images/report_corner_1.svg';
import reportCorner2 from 'media/images/report_corner_2.svg';
import {
  Campaign,
  RawQuestion,
  GroupQuestion,
  GroupAnswer,
} from 'src/graphql-api';
import { getSdk as voteStatGetSdk } from 'products/Details/Poll/query.generated';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import {
  getCampaignStatus,
  getStatusStyle,
  calcCampaignCounters,
} from 'products/CampaignList/helpers';
import {
  PROGRESS_COLORS,
  ERROR_GROUP,
} from 'products/Details/Poll/helpers';
import { GridType } from 'products/CampaignList/types';
import { PieChart } from '@mui/x-charts/PieChart';
import { get } from 'lodash';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { getSdk as campaignGetSdk } from 'products/CampaignList/query.generated';
import * as CONFIG from 'shared-scope/config';
import { BrandFiles, getBrandImg } from 'shared-scope/helpers/functions';

const MAX_PAGE_GROUP_ANSWERS = 11;
const MAX_PIE_SIZE = 9;

const apiVoteStat = voteStatGetSdk(fetcher);
const apiCampaign = campaignGetSdk(fetcher);

const loadRawVoteStat = async (campaign: Campaign) => {
  const { rawVoteStat } = await apiVoteStat.getRawVoteStat({ campaignId: campaign.id || 0 });
  return (rawVoteStat?.questions || []) as RawQuestion[];
};

const loadGroupVoteStat = async (campaign: Campaign) => {
  const { groupVoteStat } = await apiVoteStat.getGroupVoteStat({ campaignId: campaign.id || 0 });
  return (groupVoteStat?.questions || []) as GroupQuestion[];
};

function getGroupColor(groupAnswer: GroupAnswer, pageIndex: number, groupIndex: number) {
  const errorGroup = get(ERROR_GROUP, groupAnswer.group || '');
  if (errorGroup) {
    return errorGroup.color;
  }
  return PROGRESS_COLORS[(pageIndex * MAX_PAGE_GROUP_ANSWERS + groupIndex) % PROGRESS_COLORS.length];
}

const loadCampaign = async (campaignId: number) => {
  const params = {
    filter: JSON.stringify({ $and: [{ isChat: true }, { id: { $eq: campaignId } }] }),
    limit: 1,
    offset: 0,
    sort: null,
  };
  const { campaign } = await apiCampaign.getVoteSmsCampaigns(params);
  return campaign[0] as Campaign;
};


type RowProps = {
  count: number
  total: number
  title: string
  progressColor: string
};

function Row({
  count,
  total,
  title,
  progressColor,
}: RowProps) {
  const totalPercent = total > 0 ? (count / total) * 100 : 0;
  const barSize = 116;
  const progressSize = Math.round((barSize * totalPercent) / 100.0);

  return (
    <Stack direction="row" width="242px" alignItems="center">
      <Typography
        width="64px"
        fontSize="14px"
        fontWeight="500"
        color={progressColor}
      >
        {s(title)}
      </Typography>
      <Box
        width={barSize + 'px'}
        height="4px"
        marginLeft="6px"
        position="relative"
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '100px',
        }}
      >
        <Box
          width={progressSize + 'px'}
          height="4px"
          left={0}
          position="absolute"
          sx={{
            backgroundColor: progressColor,
            borderRadius: '100px',
          }}
        />
      </Box>
      <Typography
        width="48px"
        fontSize="14px"
        fontWeight="500"
        color="#000020"
        align="right"
      >
        {Math.round(totalPercent)}%
      </Typography>
    </Stack>
  );
}

type ReportPageProps = {
  title?: string
  pageNumber?: number
  questions?: boolean
  children: React.ReactNode
};

function ReportPage({
  title,
  pageNumber,
  questions,
  children,
}: ReportPageProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        '@media print': {
          width: '210mm',
          height: '279mm',
          pageBreakBefore: title ? 'always' : '',
          breakBefore: title ? 'always' : '',
        },
      }}
    >
      <Box
        width="612px"
        height="792px"
        sx={{
          position: 'relative',
          border: 1,
          borderColor: '#7C788A',
          backgroundColor: title ? '#FFFFFF' : '#120047',
        }}
      >
        {
          title ? (
            <Stack direction="row" width="564px" left="24px" top="32px" justifyContent="space-between" position="absolute">
              <Typography
                fontSize="24px"
                fontWeight="600"
                color="#120047"
              >
                {s(title)}
              </Typography>
              <img
                alt={CONFIG.get('app_name', 'Logo')}
                src={getBrandImg(BrandFiles.LogoDark)}
                height={38}
              />
            </Stack>
          ) : null
        }
        {
          questions ? (
            <Box
              left="24px"
              top="82px"
              width="564px"
              padding="16px"
              sx={{
                position: 'absolute',
                backgroundColor: '#F6F8FA',
                borderRadius: '8px',
              }}
            >
              {
                children
              }
            </Box>
          ) : children
        }
        {
          pageNumber ? (
            <Typography
              position="absolute"
              left="565px"
              top="737px"
              fontSize="12px"
              fontWeight="400"
              color="#120047"
            >
              {pageNumber}
            </Typography>
          ) : null
        }
      </Box>
    </Box>
  );
}

type PollReportDataProps = {
  campaign: Campaign
};

function PollReportData({
  campaign,
}: PollReportDataProps) {
  const [campaignSent, campaignDelivered, campaignTotal, campaignError] = calcCampaignCounters(campaign);
  const [rawQuestions, setRawQuestions] = useState<RawQuestion[]>([]);
  const [groupQuestions, setGroupQuestions] = useState<GroupQuestion[]>([]);
  let pageNumber = 2;

  const now = dayjs();

  useEffect(() => {
    const load = async () => {
      try {
        const rawVoteStatData = await loadRawVoteStat(campaign);
        setRawQuestions(rawVoteStatData);
        const groupVoteStatData = await loadGroupVoteStat(campaign);
        setGroupQuestions(groupVoteStatData);
      } catch (e: any) {
        setRawQuestions([]);
        setGroupQuestions([]);
        console.log(e);
      }
    };
    load();
  }, [campaign]);

  return (
    <Stack
      spacing="1px"
      width="100vw"
      alignItems="center"
      sx={{
        '@media print': {
          width: '215mm',
        },
      }}
    >

      {/** page 1 */}

      <ReportPage>
        <Box
          sx={{
            position: 'absolute',
            left: '40px',
            top: '43px',
          }}
        >
          <img
            alt={CONFIG.get('app_name', 'Logo')}
            src={getBrandImg(BrandFiles.LogoFull)}
            height={38}
          />
        </Box>
        <Typography
          position="absolute"
          left="484px"
          top="53px"
          fontSize="14px"
          fontWeight="400"
          color="#EF3F08"
        >
          <NavLink to="/">{CONFIG.get('app_name', '')}</NavLink>
        </Typography>

        <Typography
          position="absolute"
          left="40px"
          top="362px"
          fontSize="24px"
          fontWeight="700"
          color="#FFFFFF"
        >
          {campaign?.name}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            left: '574px',
            top: '94px',
          }}
        >
          <img alt="" src={reportStar} height="14px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: '598px',
            top: '222px',
          }}
        >
          <img alt="" src={reportStar} height="11px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: '102px',
            top: '687px',
          }}
        >
          <img alt="" src={reportStar} height="14px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            right: '0px',
            top: '0px',
          }}
        >
          <img alt="" src={reportCorner1} height="300px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: '0px',
            bottom: '0px',
          }}
        >
          <img alt="" src={reportCorner2} height="440px" />
        </Box>

        <Typography
          position="absolute"
          left="40px"
          top="732px"
          fontSize="14px"
          fontWeight="400"
          color="#73C9FA"
        >
          {s('Poll report')}
        </Typography>

        <Typography
          position="absolute"
          left="457px"
          top="712px"
          width="120px"
          align="right"
          fontSize="14px"
          fontWeight="400"
          color="#73C9FA"
        >
          {s('Generated:')}
        </Typography>

        <Typography
          position="absolute"
          left="437px"
          top="732px"
          width="140px"
          align="right"
          fontSize="14px"
          fontWeight="400"
          color="#73C9FA"
        >
          {now.format('MM/DD/YYYY HH:MM')}
        </Typography>

      </ReportPage>


      {/** page 2 */}

      <ReportPage
        title={s('Poll info')}
        pageNumber={2}
      >
        <Stack width="564px" left="24px" top="112px" position="absolute" spacing={1}>
          <Box
            height="104px"
          >
            <Stack direction="row" marginTop="12px" marginLeft="12px" marginRight="12px">
              <Stack spacing="8px" width="100%">
                <Typography
                  fontSize="12px"
                  fontWeight="500"
                  color="#7C788A"
                >
                  {s('Name')}
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="700"
                  color="#120047"
                >
                  {campaign?.name}
                </Typography>
              </Stack>
              <Stack spacing="8px" width="100px">
                <Typography
                  fontSize="12px"
                  fontWeight="500"
                  color="#7C788A"
                  align="right"
                >
                  {s('Status')}
                </Typography>
                <Box
                  width="100px"
                  height="24px"
                  alignContent="center"
                  justifyContent="center"
                  className={getStatusStyle(campaign.status || null, campaign.statusString || null)}
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    display: 'flex',
                  }}
                >
                  {(getCampaignStatus(campaign?.status?.id, GridType.Poll)?.name || '') + (campaign.statusString ? ` (${campaign.statusString})` : '')}
                </Box>
              </Stack>
            </Stack>
          </Box>

          <Box
            height="148px"
            sx={{
              backgroundColor: '#F6F8FA',
              borderRadius: '8px',
            }}
          >
            <Stack direction="row" marginTop="12px" marginLeft="12px" spacing="30px">
              <Stack marginLeft="6px" spacing="8px">
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="#000020"
                >
                  {s('Statistics')}
                </Typography>
                <Row
                  count={campaignSent}
                  total={campaignTotal}
                  title={s('Sent')}
                  progressColor="#01A76F"
                />
                <Row
                  count={campaignError}
                  total={campaignTotal}
                  title={s('Errors')}
                  progressColor="#E3001B"
                />
                <Row
                  count={campaignDelivered}
                  total={campaignSent}
                  title={s('Delivered')}
                  progressColor="#2C67FD"
                />
              </Stack>

              <Box
                width="1px"
                height="116px"
                sx={{
                  backgroundColor: '#E2E1E5',
                  borderRadius: 2,
                }}
              />

              <Stack>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="#000020"
                >
                  {s('Date')}
                </Typography>
                <Stack direction="row" marginTop="6px">
                  <Stack width="101px" spacing="8px">
                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      color="#7C788A"
                    >
                      {s('Start')}
                    </Typography>

                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      color="#000020"
                    >
                      {campaign?.startTs ? moment(campaign.startTs).format('YYYY-MM-DD') : ''}
                    </Typography>

                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      color="#7C788A"
                    >
                      {campaign?.startTs ? moment(campaign.startTs).format('HH:mm:ss') : ''}
                    </Typography>

                  </Stack>

                  <Stack marginLeft="12px" spacing="8px">
                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      color="#7C788A"
                    >
                      {s('Stop')}
                    </Typography>

                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      color="#000020"
                    >
                      {campaign?.stopTs ? moment(campaign.stopTs).format('YYYY-MM-DD') : ''}
                    </Typography>

                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      color="#7C788A"
                    >
                      {campaign?.stopTs ? moment(campaign.stopTs).format('HH:mm:ss') : ''}
                    </Typography>

                  </Stack>

                </Stack>
              </Stack>

            </Stack>
          </Box>


          <Box
            sx={{
              backgroundColor: '#F6F8FA',
              borderRadius: '8px',
            }}
          >
            <Stack marginLeft="12px" marginTop="12px" marginBottom="12px" spacing="8px">
              <Typography
                fontSize="14px"
                fontWeight="400"
                color="#000020"
              >
                {s('How many answered?')}
              </Typography>

              {
                rawQuestions.map((question, index) => {
                  const repliedTotal = (question?.answers?.map((a) => a?.count || 0).reduce((a, b) => a + b, 0) || 0);
                  const repliedPercent = campaign?.messagesSentTotal ? (repliedTotal * 100.0) / campaign.messagesSentTotal : 0;
                  const barSize = 426;
                  const progressSize = Math.round((barSize * repliedPercent) / 100.0);

                  return (
                    <Stack direction="row" height="37px" spacing="20px" alignItems="center" key={question.position}>
                      <Box
                        width="8px"
                        height="8px"
                        sx={{
                          backgroundColor: PROGRESS_COLORS[index],
                          borderRadius: '2px',
                        }}
                      />
                      <Typography
                        width="72px"
                        fontSize="14px"
                        fontWeight="400"
                        color="#7C788A"
                      >
                        {(question.position || 0) < 0 ? s('Engagement question') : s('Question') + ' ' + question.position}
                      </Typography>
                      <Stack width="426px" spacing="8px">
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            color="#000020"
                          >
                            {repliedPercent.toFixed(1)}%
                          </Typography>

                          <Typography
                            fontSize="12px"
                            fontWeight="500"
                            color="#7C788A"
                          >
                            {repliedTotal}
                          </Typography>
                        </Stack>

                        <Box
                          width={barSize + 'px'}
                          height="6px"
                          position="relative"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            borderRadius: '100px',
                          }}
                        >
                          <Box
                            width={progressSize + 'px'}
                            height="6px"
                            left={0}
                            position="absolute"
                            sx={{
                              backgroundColor: PROGRESS_COLORS[index],
                              borderRadius: '100px',
                            }}
                          />
                        </Box>

                      </Stack>
                    </Stack>
                  );
                })
              }

            </Stack>
          </Box>
        </Stack>
      </ReportPage>

      {/** page 3 */}

      {
        groupQuestions.map((question) => {
          const questionGroups = question?.groups || [];
          let totalAnswers = 0;
          const questionPages = questionGroups.reduce((pages, currentGroup, groupIndex) => {
              const pageIndex = Math.floor(groupIndex / MAX_PAGE_GROUP_ANSWERS);
              if (currentGroup) {
                if (pages.length <= pageIndex) {
                  pages.push([]);
                }
                totalAnswers += currentGroup.count || 0;
                pages[pageIndex].push(currentGroup);
              }
              return pages;
            }, [[]] as GroupAnswer[][]);
          return questionPages.map((pageGroups, pageIndex) => {
              const pieCharts = pageGroups ? pageGroups.length <= MAX_PIE_SIZE : false;
              pageNumber += 1;
              return (
                <ReportPage
                  title={s('Results')}
                  pageNumber={pageNumber}
                  questions
                  key={pageNumber}
                >
                  <Stack spacing="8px">
                    <Typography
                      fontSize="12px"
                      fontWeight="500"
                      color="#7C788A"
                    >
                      {(question.position || 0) < 0 ? s('Engagement question') : s('Question') + ' ' + question.position}
                    </Typography>
                    <Stack direction="row" width="532px" justifyContent="space-between">
                      <Typography
                        fontSize="16px"
                        fontWeight="500"
                        color="#000020"
                      >
                        {question.question}
                      </Typography>
                      <Typography
                        fontSize="12px"
                        fontWeight="500"
                        color="#7C788A"
                        minWidth="120px"
                      >
                        Total answers: {totalAnswers}
                      </Typography>
                    </Stack>

                    <Stack direction="row" hidden={pageGroups.length === 0}>
                      <Stack>
                        {
                          pageGroups.map((groupAnswer, groupIndex) => {
                            const barSize = 220;
                            const progressSize = Math.round((barSize * (groupAnswer.pct || 0)) / 100.0);
                            const groupName = (groupAnswer?.keyword ? 'Keyword: ' : '') + (groupAnswer?.group || '');
                            const errorGroup = get(ERROR_GROUP, groupName);

                            return (
                              <Stack width={pieCharts ? '296px' : '516px'} key={groupAnswer?.group || groupIndex}>
                                <Stack direction="row" alignItems="center">
                                  <Box
                                    width="8px"
                                    height="8px"
                                    sx={{
                                      backgroundColor: getGroupColor(groupAnswer, pageIndex, groupIndex),
                                      borderRadius: '2px',
                                    }}
                                  />
                                  <Typography
                                    fontSize="14px"
                                    fontWeight="400"
                                    color="#000020"
                                    noWrap
                                    marginLeft="8px"
                                    width="280px"
                                  >
                                    {errorGroup?.label || groupName}
                                  </Typography>
                                  {
                                    pieCharts ? null : (
                                      <Stack direction="row" width="220px" justifyContent="space-between">
                                        <Typography
                                          fontSize="14px"
                                          fontWeight="500"
                                          color="#000020"
                                        >
                                          {Math.round(groupAnswer.pct || 0)} %
                                        </Typography>
                                        <Typography
                                          fontSize="12px"
                                          fontWeight="500"
                                          color="#7C788A"
                                        >
                                          {groupAnswer.count || 0}
                                        </Typography>
                                      </Stack>
                                    )
                                  }
                                </Stack>

                                <Stack direction="row" alignItems="center">
                                  <Typography
                                    fontSize="12px"
                                    fontWeight="400"
                                    color="#7C788A"
                                    noWrap
                                    marginLeft="16px"
                                    width="280px"
                                  >
                                    {(groupAnswer?.answers || []).join('; ')}
                                  </Typography>
                                  {
                                    pieCharts ? null : (
                                      <Box
                                        width={barSize + 'px'}
                                        height="6px"
                                        position="relative"
                                        sx={{
                                          backgroundColor: '#FFFFFF',
                                          borderRadius: '100px',
                                        }}
                                      >
                                        <Box
                                          width={progressSize + 'px'}
                                          height="6px"
                                          left={0}
                                          position="absolute"
                                          sx={{
                                            backgroundColor: getGroupColor(groupAnswer, pageIndex, groupIndex),
                                            borderRadius: '100px',
                                          }}
                                        />
                                      </Box>

                                    )
                                  }
                                </Stack>
                              </Stack>
                            );
                          })
                        }
                      </Stack>
                      {
                        pieCharts ? (
                          <Stack direction="row" alignItems="center" width="220px">
                            <PieChart
                              series={[
                                {
                                  data: pageGroups?.map((groupAnswer, groupIndex) => {
                                      return {
                                        groupIndex,
                                        value: groupAnswer.pct || 0,
                                        label: groupIndex + '',
                                        color: getGroupColor(groupAnswer, pageIndex, groupIndex),
                                      };
                                    }) || [],
                                  startAngle: -270,
                                  endAngle: 90,
                                },
                              ]}
                              slotProps={{
                                legend: {
                                  hidden: true,
                                },
                              }}
                              width={400}
                              height={220}
                            />
                            <Stack spacing={1} sx={{ ml: -5 }} width="100%">
                              {pageGroups?.map((groupAnswer, groupIndex) => {
                                return (
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    key={groupAnswer?.group || groupIndex}
                                  >
                                    <Box
                                      width="8px"
                                      height="8px"
                                      sx={{
                                        backgroundColor: getGroupColor(groupAnswer, pageIndex, groupIndex),
                                        borderRadius: '2px',
                                      }}
                                    />
                                    <Stack direction="row" justifyContent="space-between" width={90} alignItems="center">
                                      <Typography
                                        fontSize="14px"
                                        fontWeight="500"
                                        color="#000020"
                                        width="36px"
                                        align="right"
                                      >
                                        {Math.round(groupAnswer.pct || 0)} %
                                      </Typography>
                                      <Typography
                                        fontSize="12px"
                                        fontWeight="500"
                                        color="#7C788A"
                                      >
                                        {groupAnswer.count || 0}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                );
                              })}
                            </Stack>
                          </Stack>


                        ) : null
                      }
                    </Stack>
                  </Stack>
                </ReportPage>
              );
          });
        })
      }

    </Stack>
  );
}


export default function PollReport() {
  const { id } = useParams();
  const [campaign, setCampaign] = useState<Campaign | null>();
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      try {
        if (id) {
          const campaignData = await loadCampaign(parseInt(id, 10));
          setCampaign(campaignData);
        } else {
          setCampaign(null);
        }
      } catch (e: any) {
        setCampaign(null);
        console.log(e);
      }
    };
    load();
  }, [id]);

  return (
    <Stack width="100vw">
      <Box sx={{ display: 'block', displayPrint: 'none' }}>
        <Stack direction="row" spacing={2} padding={2}>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
          >
            {s('Close')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                window.print();
              }}
          >
            {s('Print report')}
          </Button>
        </Stack>
      </Box>
      {
        campaign ? (
          <PollReportData
            campaign={campaign}
          />
        ) : null
      }
    </Stack>
  );
}
