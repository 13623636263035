import {
 MenuItem, Select, Stack, Typography, useTheme,
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { DataGridPro, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { gridStyles, formatNumber } from '@xeebi/neru';
import { s } from 'i18n';
import isEmpty from 'lodash/isEmpty';
import { isEqual } from 'lodash';
import {
 Sources, Mapping, MappingData, MappingFormValue,
} from '../types';
import {
  ImportColumns, MAPPING_CAMPAIGN, MAPPING_PHONE_BOOK,
  MappingColumnsTitles,
} from '../helpers/const';

const DO_NOT_IMPORT = 'do_not_import';
const ROW_COUNT = 15;

export default function MappingTable({
 data, mode, mappingParams, onChange: setMapping, value: mapping,
}: MappingTableProps) {
  const { palette } = useTheme();

  const [dataMapping, setDataMapping] = useState<Mapping>({});

  const preparedData = useMemo(
    () => mode === Sources.phonebook || mode === Sources.campaign,
    [mode],
  );

  const defaultMapping = useCallback((sourceMode: Sources) => {
    let dfltMapping;
    switch (sourceMode) {
      case Sources.phonebook:
        dfltMapping = MAPPING_PHONE_BOOK;
        break;
      case Sources.campaign:
        dfltMapping = MAPPING_CAMPAIGN;
        break;
      default:
        dfltMapping = {};
    }
    return dfltMapping;
  }, []);

  /**
   * set Mapping by mode
   */
  useEffect(() => {
    mode && setMapping(defaultMapping(mode));
  }, [mode, defaultMapping, setMapping]);

  /**
   * Set mapping if empty
   */
  useEffect(() => {
    const modeMapping = mode ? defaultMapping(mode) : {};
    isEmpty(mapping) && !isEmpty(modeMapping) && setMapping(modeMapping);
  }, [mapping, defaultMapping, mode, setMapping]);

  useEffect(() => {
    !isEqual(dataMapping, data?.mapping || {}) && setDataMapping(data?.mapping || {});
  }, [dataMapping, data?.mapping]);
  /**
   * Set Mapping by Source data
   */
  useEffect(() => {
    if (!isEmpty(dataMapping)) {
      const m: Mapping = {};
      for (const k of Object.keys(dataMapping || {})) {
        if (k in ImportColumns) {
          m[k] = dataMapping[k];
        }
      }
      setMapping(m);
    }
  }, [dataMapping, setMapping]);

  const renderHeader = useCallback(
    (params: GridColumnHeaderParams) => {
      let headerValue = DO_NOT_IMPORT;
      for (const k in mapping) {
        if (params.colDef.field === mapping[k].toString()) {
          headerValue = k;
          break;
        }
      }

      return preparedData
        ? <div>{MappingColumnsTitles[headerValue] || ''}</div>
        : (
          <Select
            value={headerValue}
            onBlur={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              const v = e.target.value || DO_NOT_IMPORT;
              const newMapping: { [key: string]: number } = {};
              for (const k in mapping) {
                if (params.colDef.field !== mapping[k].toString()) {
                  newMapping[k] = mapping[k];
                }
              }
              if (v !== DO_NOT_IMPORT) {
                newMapping[v] = parseInt(params.colDef.field, 10);
              }
              setMapping(newMapping);
            }}
            sx={{
              width: '200px',
              color: headerValue === DO_NOT_IMPORT ? palette.muted.main : palette.normal.main,
            }}
          >
            {
              Object.entries(ImportColumns)
                .map((c) => {
                  const [key, title] = c;
                  if (key === DO_NOT_IMPORT) {
                    return <MenuItem value={key} key={key} divider>{title}</MenuItem>;
                  }
                  return <MenuItem value={key} key={key}>{title}</MenuItem>;
                })
            }
          </Select>
        );
    },
    [mapping, palette, preparedData, setMapping],
  );

  const rowset = useMemo(() => {
    if (!data) {
      return [];
    }
    const rows: any = [];
    let lines: any = [];
    if (preparedData) {
      lines = data.data_rowset || [];
    } else if (data.file_type === 'csv') {
      const delimiter = mappingParams.delimiter || ',';
      const quoteCharacter = mappingParams.quoteCharacter || '"';
      for (const line of data.data_lines?.split('\n') || []) {
        const newLine: string[] = [];
        for (const v of line.split(delimiter)) {
          if (v.startsWith(quoteCharacter) && v.endsWith(quoteCharacter)) {
            newLine.push(v.substring(1, v.length - 1));
          } else {
            newLine.push(v);
          }
        }
        lines.push(newLine);
      }
    } else if (data.file_type === 'excel') {
      const sheet = mappingParams.sheet || 0;
      lines = data.data_sheets?.[sheet] || [];
    }

    const skipTopRows = +(mappingParams.skipTopRows || 0);
    const cutDigits = mappingParams.cutDigits || 0;
    const addPrefix = mappingParams.addPrefix || '';
    for (let j = skipTopRows; j < lines.length; j += 1) {
      const row: any = { id: j };
      for (let i = 0; i < lines[j].length; i += 1) {
        row[i] = lines[j][i];
      }
      if (
        mapping.phone_number !== undefined
        && mapping.phone_number !== null
        && (!!cutDigits || !!addPrefix)
      ) {
        let v = row[mapping.phone_number];
        if (cutDigits) {
          v = v.substring(cutDigits);
        }
        if (addPrefix) {
          v = `${addPrefix}${v}`;
        }
        row[mapping.phone_number] = v;
      }
      rows.push(row);
    }
    return rows.splice(0, ROW_COUNT);
  }, [data, mappingParams, mapping.phone_number, preparedData]);

  const columns = useMemo(
    () => {
      const newColumns: Record<string, GridColDef> = {};
      const result: GridColDef[] = [];

      for (const row of rowset) {
        for (const fieldName of Object.keys(row)) {
          if (!(fieldName === 'id' || newColumns[fieldName])) {
            newColumns[fieldName] = {
              field: fieldName,
              headerName: fieldName,
              width: 200,
              hideSortIcons: true,
              renderHeader,
              sortable: false,
              filterable: false,
            };
          }
        }
      }
      const keys = (Object.keys(newColumns)).sort(
        (a, b) => (parseInt(a, 10) < parseInt(b, 10) ? -1 : 1),
      );
      for (const k of keys) {
        result.push(newColumns[k]);
      }
      preparedData && result.shift(); //remove address_id column
      return result;
    },
    [rowset, preparedData, renderHeader],
  );

  return columns ? (
    <Stack spacing={1}>
      {(data.data_count || 0)
        ? <Typography variant="h4">
          {s(
            'Contacts: :cnt',
            { cnt: formatNumber({ value: data.data_count || 0 }) },
          )}
        </Typography>
        : null}
      <DataGridPro
        rows={rowset}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnReorder
        disableChildrenSorting
        disableColumnPinning
        disableColumnSelector
        disableMultipleColumnsSorting
        hideFooter
        sx={gridStyles}
      />
    </Stack>
  ) : null;
}

type MappingTableProps = {
  mode?: Sources
  data: MappingData
  mappingColumns?: Mapping
  mappingParams: MappingFormValue
  value: Mapping
  onChange: (v: Mapping) => void
};

