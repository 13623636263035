import { NeruThemeProvider, Theme as NeruTheme } from '@xeebi/neru';
import { ThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';
import * as CONFIG from 'shared-scope/config';
import themeDefault from './theme-default';

export default function Theme({ children = null }: PropsWithChildren<any>) {
  let theme;
  switch (CONFIG.get('color_scheme', '')) {
    case 'orange':
    default:
      theme = themeDefault;
  }

  return <NeruThemeProvider theme={theme as NeruTheme}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </NeruThemeProvider>;
}
