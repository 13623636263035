import React, { useState } from 'react';
import Loading from 'shared-scope/components/Loading';
import {
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { ToolsType, ToolType } from 'products/CampaignList/components/Tools';
import { useNavigate } from 'react-router-dom';


export default function Toolbox({
  campaignId,
  campaignName,
  tools,
  loading = false,
  loadingSize = 20,
  onAction,
}: ToolboxProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const actionHandler = (t: ToolType) => {
    const confirm = t.confirmation ? t.confirmation(campaignName) : null;
    t.handler && onAction && onAction(t.handler, confirm, t.key);

    if (t.redirect) {
      const path = (typeof t.redirect === 'string') ? t.redirect : t.redirect(campaignId);
      navigate(path);
    }
  };
  return (
    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
      {loading
        ? <Loading logo={false} size={loadingSize} />
        : (
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                tools[0] && actionHandler(tools[0]);
              }}
              aria-label={tools[0].caption}
              sx={{ marginLeft: '0px' }}
            >
              {tools[0].icon}
            </IconButton>
            {tools.length > 1
              ? (
                <Icon
                  sx={{ transform: 'rotate(90deg)' }}
                  className="icon-actions"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                    setOpen(true);
                  }}
                />
              )
              : null}
          </>
        )}
      <Menu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={anchorEl}
      >
        {tools.slice(1).map((t) => (
          <MenuItem
            key={t.key}
            onClick={() => {
              actionHandler(t);
              setOpen(false);
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {t.icon}
              <Typography>{t.caption}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}


export interface ToolboxProps {
  campaignId: number
  campaignName: string
  tools: ToolsType
  loadingSize?: number
  loading?: boolean
  onAction?: (action: Function, confirm: string | null, key: string) => any
  className?: string
  buttonStyle?: string
  short?: boolean
  toolsStyle?: string
}
