import {
  Box, ImageListItem, Stack, Tooltip, Typography, useTheme,
} from '@mui/material';
import * as CONFIG from 'shared-scope/config';
import fileCheck from 'media/images/file_check.svg';
import * as moment from 'moment-timezone';
import React from 'react';
import { Attachment } from 'graphql-api';
import { formatFileSize } from 'products/common/helpers';

export default function AttachmentItem(
  {
    attachment,
    selected,
    onClick,
    onDelete,
    disabled = false,
    error = '',
  }: AttachmentItemProps) {
  const { palette } = useTheme();

  return attachment.id ? (
    <ImageListItem sx={{ maxWidth: '200px', marginTop: '20px' }}>
      <Stack alignItems="center">
        <Box sx={{ bgColor: '#FFFF00' }}>
          <Box
            sx={{
              height: '100px',
              width: '166px',
              position: 'relative',
              '&:hover': {
                '.deleteAttachment': {
                  display: 'block',
                },
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                zIndex: 1,
                backgroundColor: '#FEF8F6',
                justifyContent: 'center',
                display: 'flex',
                cursor: disabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => !disabled && onClick()}
            >
              <Tooltip title={disabled ? error : ''}>
                <img
                  src={`${CONFIG.get('api_host')}${PUBLIC_PATH}api/fs/attachment-file/${attachment.id}`}
                  style={{
                    maxWidth: '160px',
                    maxHeight: '100px',
                    filter: disabled ? 'blur(5px)' : 'none',
                  }}
                  alt=""
                  loading="eager"
                  onError={(event) => {
                    event.currentTarget.src = fileCheck;
                  }}
                />
              </Tooltip>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 10,
                backgroundColor: '#FFFFFF',
                width: '28px',
                height: '28px',
                display: 'none',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#CCCCCC',
                },
              }}
              onClick={() => onDelete()}
              className="deleteAttachment"
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  color: palette.error.main,
                }}
                align="center"
              >
                <i className="icon-delete" />
              </Typography>
            </Box>
            {
              selected ? (
                <Box
                  sx={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    zIndex: 10,
                    backgroundColor: '#FFFFFF',
                    borderTopRightRadius: '8px',
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '24px',
                      color: palette.primary.main,
                    }}
                    align="center"
                  >
                    <i className="icon-number_check" />
                  </Typography>
                </Box>
              ) : null
            }
          </Box>
        </Box>
        <Tooltip title={(attachment.name || '').length > 20 ? attachment.name : ''} arrow>
          <Typography
            noWrap
            sx={{
              color: palette.normal.main,
              fontSize: '14px',
              fontWeight: 400,
              maxWidth: '160px',
            }}
          >{attachment.name}</Typography>
        </Tooltip>
        <Typography
          noWrap
          variant="subtitle2"
        >
          {moment.utc(attachment.createTs)
            .format('YYYY-MM-DD HH:mm:ss')}
          {attachment.size ? ', ' + formatFileSize(attachment.size) : ''}
        </Typography>
      </Stack>
    </ImageListItem>
  ) : null;
}

type AttachmentItemProps = {
  attachment: Attachment
  selected: boolean
  onClick: () => void
  onDelete: () => void
  disabled?: boolean
  error?: string
};
