import { s } from 'i18n';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  CampaignStatus,
  ConversationTemplate,
} from 'src/graphql-api';
import { getSdk as templateGetSdk } from 'products/Templates/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import {
 DataGrid, ApiQueryParams, ApiQueryResult, useGridRef,
} from '@xeebi/neru';
import Imports from 'products/common/CampaignShared/Imports';
import { ImportType } from 'products/common/types';
import { hasPermissionById, PERMISSION_GENERAL } from 'permission';
import { CampaignDetailsProps } from 'products/Details';
import TemplateType from 'products/Templates/types';
import Struct from './Struct';
import { GridType } from './types';
import { getCampaignStatuses } from './helpers';
import { getSdk as campaignGetSdk } from './query.generated';


const apiCampaign = campaignGetSdk(fetcher);
const apiTemplate = templateGetSdk(fetcher);

const loadCampaigns = async (params: ApiQueryParams) => {
  const gridFilter = params.filter === 'null' ? '' : `, ${params.filter}`;
  params.filter = `{"$and":[{"isChat":false}, {"type": {"$in": [0, 1, 6]}}${gridFilter}]}`;
  const { campaignCount, campaign } = await apiCampaign.getVoteSmsCampaigns(params);
  return { count: campaignCount, rows: campaign } as ApiQueryResult;
};

export const loadPolls = async (params: ApiQueryParams) => {
  params.filter = params.filter === 'null'
    ? '{"$and":[{"isChat":true}, {"type": {"$in": [0, 1, 2, 3, 4, 6]}}]}'
    : `{"$and":[{"isChat":true}, {"type": {"$in": [0, 1, 2, 3, 4, 6]}}, ${params.filter}]}`;
  const { campaignCount, campaign } = await apiCampaign.getVoteSmsCampaigns(params);
  return { count: campaignCount, rows: campaign } as ApiQueryResult;
};

export default function Grid({
    gridType,
}: GetGridProps) {
  const navigate = useNavigate();
  const gridRef = useGridRef();
  const [templates, setTemplates] = useState<ConversationTemplate[]>([]);

  const campaignStatuses = useMemo(() => getCampaignStatuses(gridType) as CampaignStatus[], [gridType]);

  const hasActionPermission = hasPermissionById(PERMISSION_GENERAL);
  const path = gridType === GridType.Campaign ? '/campaign' : '/poll';

  useEffect(() => {
    const loadStatuses = async () => {
      try {
        const { conversationTemplate } = await apiTemplate.getConversationTemplate({
          filter: gridType === GridType.Campaign
            ? `{"type": ${TemplateType.Text}}`
            : gridType === GridType.Poll
              ? `{"type": ${TemplateType.Poll}}`
              : `{"type": ${TemplateType.OptIn}}`,
          sort: '[{"name": 1}]',
        });
        setTemplates(conversationTemplate);
      } catch (e) {
        console.log(e);
      }
    };
    loadStatuses();
  }, [gridType]);

  const columns = useMemo(() => {
    return Struct({
      campaignStatuses, templates, gridRef, gridType, hasActionPermission,
    });
  }, [campaignStatuses, templates, gridRef, gridType, hasActionPermission]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Imports type={ImportType.Campaign} title={s('Creating campaigns')} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          primaryKey="id"
          storageId={'CampaignGrid' + gridType + 'Storage'}
          refreshTimeout={15_000}
          pageSize={12}
          apiRef={gridRef}
          getRows={gridType === GridType.Campaign ? loadCampaigns : loadPolls}
          columns={columns}
          onRowClick={(row) => {
            navigate(`${path}/${row.id}`);
          }}
        />

        <Outlet
          context={{
            gridRef,
            type: gridType,
          } as CampaignDetailsProps}
        />
      </Box>
    </>
  );
}

type GetGridProps = {
  gridType: GridType
};
