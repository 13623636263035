import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import React, { useCallback, useEffect, useState } from 'react';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import {
  DEFAULTS, SchedulerLabel, FormField, request, Sources,
} from 'products/shared/Campaign';
import {
  Actions, StepGeneral, StepQuestions, StepScheduler, StepSettings, StepTarget,
  StepGeneralRow, StepQuestionRow, StepSchedulerRow, StepSettingsRow, StepTargetRow,
} from 'products/shared/Campaign/steps';
import { useRequest } from '@xeebi/neru';
import { Typography } from '@mui/material';
import {
  cloneDeep, get, isEmpty, omit,
} from 'lodash';
import { SOURCE_RESPONDER } from 'products/CampaignList/helpers';
import { CAMPAIGN_TYPE_RESPONDER } from 'products/common/helpers';
import { CountryCapability } from 'graphql-api';
import { SourcePhoneBookValue } from 'products/shared/Campaign/types';

const back = '/poll';
export const ACTION_CHAT_BOT = 6;

export default function NewPoll() {
  const form = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { addSuccess, addError } = useAlert();
  const {
    isLoading, error, fetch: createPoll, result,
  } = useRequest(request.createCampaign);

  const [schedulerOn, setSchedulerOn] = useState(false);
  const templateId = new URLSearchParams(location.search).get('templateId');

  const onSubmit = useCallback(async (row: NewPollRow) => {
    await createPoll(getCampaignData(row, schedulerOn));
  }, [schedulerOn, createPoll]);

  useEffect(() => {
    error && addError(s('Error creating a new poll'));
  }, [error, addError]);

  useEffect(() => {
    result && addSuccess(s('New poll added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  return (
    <Page title={s('Create new poll')} back={back} loading={isLoading}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral titleFieldName={s('Poll name')} />
          </Step>
          <Step label={s('Target Audience')}>
            <StepTarget
              avail={[Sources.excel, Sources.text, Sources.phonebook]}
              targetType="poll"
            />
          </Step>
          <Step label={s('Settings')}>
            <StepSettings
              sourceType={form.getValue(FormField.sourceType)}
              sourceValue={form.getValue(FormField.sourceValue) || null}
            />
          </Step>
          <Step label={s('Questions')}>
            <StepQuestions
              sourceType={form.getValue(FormField.sourceType)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={templateId ? +templateId : null}
            />
          </Step>
          <Step label={<SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler />
              : <Typography variant="subtitle1">{s('Poll will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create new poll')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}

export function getCampaignData(row: NewPollRow, schedulerOn: boolean) {
  const isResponder = row.source_type === Sources.responder;
  let phoneBookFilter = get(row?.source_value, 'phone_book_filter', null);
  phoneBookFilter = isEmpty(phoneBookFilter) ? null : phoneBookFilter;

  const conversationTemplate = cloneDeep(row.questions?.template);
  if (conversationTemplate) {
    conversationTemplate.final_attachment_id = row.route?.maxAttachmentSize ? conversationTemplate?.final_attachment_id : null;
    delete conversationTemplate?.final_error;
    conversationTemplate.questions = conversationTemplate.questions?.map((q) => {
      const attId = row.route?.maxAttachmentSize ? q.attachment_id : null;
      delete q.error;
      return { ...q, ...{ attachment_id: attId } };
    }) || [];
  }
  const data = {
    ...DEFAULTS,
    ...{
      campaign_name: row.title,
      country_id: row.route?.country?.id,
      connection_id: row.route?.route?.id,
      reply_actions: [{
        keywords: row.questions?.initialAnswer || '',
        action_type: ACTION_CHAT_BOT,
        message_template: null,
      }],
      conversation_template: conversationTemplate,
      conversation_template_id: row.template?.id,
      src_address: row?.number?.number || null,
      src_address_id: row?.number?.number_id || null,
      type: row.type,
      address_book_id: (row.source_value as SourcePhoneBookValue)?.phone_book_id || null,
      phone_book_filter: phoneBookFilter,
      send_to_existing_contacts: row?.send_to_existing_contacts,
    },
  };

  if (!isResponder) {
    Object.assign(data, {
      ...omit(row.source_value?.data, ['data_lines', 'data_rowset', 'data_sheets', 'file']),
      ...omit(row.source_value, ['data']),
      ...{
        mapping: row.mapping,
        skip: row.mapping_param?.skipTopRows,
        phone_add: row.mapping_param?.addPrefix,
        phone_cut: row.mapping_param?.cutDigits,
        delimiter: row.mapping_param?.delimiter,
        enclosure: row.mapping_param?.quoteCharacter,
        sheets: row.mapping_param?.sheet,
        random: row.mapping_param?.random,
        randomEnabled: !!row.mapping_param?.random,

        msg_templates: [{
          text: row.questions?.initialMessage,
          enabled: true,
        }],
        attachment: row.route?.maxAttachmentSize ? row.questions?.initialAttachment?.id : null,
      },
    });
  } else {
    Object.assign(data, {
      source_type: SOURCE_RESPONDER,
      type: CAMPAIGN_TYPE_RESPONDER,
    });
  }

  schedulerOn && Object.assign(data, {
    schedule_times: row.schedule_times,
    schedule_tz: row.schedule_tz,
    schedule_enabled: true,
  });
  return data;
}

export type NewPollRow = StepGeneralRow & StepTargetRow & StepSettingsRow & StepQuestionRow & StepSchedulerRow;
