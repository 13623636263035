import { runWithInterval, Runner, useMountedState } from '@xeebi/neru';
import { useCallback, useEffect, useState } from 'react';
import { fetcher } from 'graphql-api/fetcher';
import { Import } from 'graphql-api';
import { Box, Stack, Typography } from '@mui/material';
import { ImportStatus } from 'products/common/types';
import ImportItem from 'products/common/CampaignShared/components/ImportItem';
import dayjs from 'dayjs';
import { MangoFilter } from 'shared-scope/types';
import { getSdk } from '../queries.generated';

const api = getSdk(fetcher);
const ACTIVE_INTERVAL = 10_000;
const DEFAULT_INTERVAL = 60_000;

export default function Imports({
 title = '', type, dstId, statusFilter = true, last3days = true,
}: ImportsProps) {
  const [imports, setImports] = useState<Import[]>([]);
  const [interval, setInterval] = useState(DEFAULT_INTERVAL);
  const [runner, setRunner] = useState<Runner | null>(null);

  const isMounted = useMountedState();

  const loadImports = useCallback(async () => {
    try {
      const defaultStatusFilter = [ImportStatus.Queued, ImportStatus.Importing, ImportStatus.Error];
      const statuses = statusFilter === true ? defaultStatusFilter : statusFilter;

      const filter: MangoFilter = [{ type }, { confirmedTs: null }];
      dstId && filter.push({ dstId });
      statuses && filter.push({ status: { $in: statuses } });
      last3days && filter.push({ createTs: { $gte: dayjs().subtract(3, 'days').format('YYYY-MM-DDTHH:mm:ss') } });

      const newImports = await api.getImport({
        filter: JSON.stringify({
          $and: filter,
        }),
        sort: '[{"id": -1}]',
      });

      isMounted() && setImports(newImports.import);
    } catch (e) {
      console.error(e);
      setImports([]);
    }
  }, [type, dstId, statusFilter, last3days, isMounted]);

  const confirmImport = useCallback(async (id: number) => {
    try {
      id && await api.confirmImport({ id });
    } catch (e) {
      console.error(e);
    } finally {
      loadImports();
    }
  }, [loadImports]);

  //Set interval
  useEffect(() => {
    const hasActiveImports = imports.some(
      (imp: Import) => imp.status && [ImportStatus.Queued, ImportStatus.Importing].includes(imp.status),
    );
    setInterval(hasActiveImports ? ACTIVE_INTERVAL : DEFAULT_INTERVAL);
  }, [imports]);

  //Auto update
  useEffect(() => {
    runner?.stop();
    setRunner(runWithInterval(loadImports, interval));

    return () => {
      runner?.stop();
    };

    // don't need runner in deps list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, loadImports]);

  return imports.length ? <Box sx={{
      width: '100%',
      border: 1,
      borderRadius: '8px',
      padding: 2,
    }}
  >
    <Stack spacing={1}>
      <Typography variant="h4">{title}</Typography>
      {imports.map((imp) => (
        imp.id && <ImportItem
          data={imp}
          onClose={() => confirmImport(imp.id || 0)}
          key={imp.id}
        />
      ))}
    </Stack>
  </Box> : null;
}

type ImportsProps = {
  title?: string
  type: number
  dstId?: number
  statusFilter?: boolean | ImportStatus[]
  last3days?: boolean
};
