import FileUploader from 'shared-scope/components/FileUploader';
import {
  useEffect, useState,
} from 'react';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest, useMountedState } from '@xeebi/neru';
import { uploadSource } from '../request';
import { SourceFileValue, MappingData } from '../types';

const SOURCE_TYPE = 'source-file';

export default function SourceFile(
  {
    onChange,
    file,
    onFile,
    disabled = false,
  }: SourceFileProps,
) {
  const [uploadedData, setUploadedData] = useState<MappingData | null>(null);
  const [progress, setProgress] = useState(0);

  const isMounted = useMountedState();
  const {
    isLoading,
    error,
    fetch,
  } = useRequest(uploadSource);
  const { addError } = useAlert();

  useEffect(() => {
    const upload = async () => {
      if (!file) {
        return;
      }

      const { data } = await fetch(
        {
          source_type: SOURCE_TYPE,
          file,
        },
        {
          onUploadProgress: (progressEvent: any) => {
            if (file?.size && file.size > 0) {
              const p = Math.round((progressEvent.loaded / file.size) * 100);
              setProgress(p <= 100 ? p : 100);
            }
          },
        },
      );

      if (isMounted()) {
        setUploadedData(data);
        setProgress(0);
      }
    };

    isMounted() && upload();
  }, [file, isMounted, fetch]);

  useEffect(() => {
    error && addError(error.getMessage());
  }, [error, addError]);

  useEffect(() => {
    uploadedData && onChange({
      data: uploadedData,
      source_type: SOURCE_TYPE,
    });
  }, [uploadedData, onChange]);

  return <FileUploader
    onChange={onFile}
    value={file}
    progress={progress}
    disabled={disabled}
    loading={isLoading}
  />;
}

type SourceFileProps = {
  onChange: (v: SourceFileValue) => void
  file: File | null
  onFile: (f: File | null) => void
  disabled?: boolean
};
