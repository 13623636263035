import Page from 'layout/Page';
import { s } from 'i18n';
import { Stepper, Step } from 'shared-scope/components/Stepper';
import ActionsBox from 'shared-scope/components/Box/ActionsBox';
import { Button, Stack, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSdk } from 'products/PhoneBook/query.generated';
import { fetcher } from 'graphql-api/fetcher';


const api = getSdk(fetcher);

export default function EditFolder() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const { id } = useParams();
  const folderId = parseInt(id || '-1', 10);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [phoneBookFolder] = (await api.getPhoneBookFolder({
          filter: JSON.stringify({ id: folderId }),
        })).phoneBookFolder;
        if (phoneBookFolder) {
          setName(phoneBookFolder.name || '');
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadData();
  }, [folderId]);

  return (
    <Page title={s('Edit folder')} back="/phone-book">
      <Stepper>
        <Step label={s('General')}>
          <Stack>
            <TextField
              label={s('Folder name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              sx={{ width: '600px' }}
              required
            />
          </Stack>
        </Step>
      </Stepper>
      <ActionsBox>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={() => navigate(-1)}>{s('Cancel')}</Button>
          <Button variant="contained"
            onClick={() => {
              api.phoneBookFolderSave({
                filter: JSON.stringify({ id: folderId }),
                input: { name },
              });
              navigate(-1);
            }}
          >{s('Save changes')}</Button>
        </Stack>
      </ActionsBox>
    </Page>
  );
}
