import React, {
  useCallback, useMemo, useState, lazy, Suspense, useEffect, useRef,
} from 'react';
import { isEqual } from 'lodash';
import { Backdrop, Alert } from '@mui/material';
import axios from 'axios';
import { CookieAttributes } from 'js-cookie';
import {
  setSSID,
  getSSID,
  initSSID,
} from 'shared-scope/auth';
import { runWithInterval } from '@xeebi/neru';
import { AppContext, AppContextProvider, Alert as AlertType } from 'shared-scope/AppContext';
import * as CONFIG from 'shared-scope/config';
import { getErrorMsg, UID } from 'shared-scope/helpers/functions';
import { getSdk as getSdkCommon, RefreshAccountQuery } from 'products/common/queries.generated';
import Loading from 'shared-scope/components/Loading';
import { fetcher } from 'graphql-api/fetcher';
import { User } from 'graphql-api';
import { getSdk } from 'auth/queries.generated';
import initAnalytics from './ga';
import Router from './Router';

const Auth = lazy(() => {
  return import('./auth');
});

axios.defaults.withCredentials = true;

const api = getSdk(fetcher);
const apiCommon = getSdkCommon(fetcher);

export default function App() {
  // Loading
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  // User
  const [user, setUser] = useState<User | null>(null);
  const setUserContext = useCallback((u: Partial<User> | null) => {
    setUser(u ? (prev) => ({ ...(prev || {}), ...u }) : null);
  }, [setUser]);
  const [account, setAccount] = useState<RefreshAccountQuery | null>(null);

  const [notificationReadId, setNotificationReadId] = useState<number | null>(null);

  // Token
  const [token, setToken] = useState<string>(getSSID());
  const setTokenContext = useCallback((t: string, cookieParams: CookieAttributes = {}) => {
    setSSID(t, cookieParams);
    setToken(t);
  }, []);

  initSSID(getSSID(), {});

  //Alerts
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  useEffect(() => {
    const alertsWithID: AlertType[] = alerts.map((alert) => ({ id: UID(), ...alert }));
    if (!isEqual(alerts, alertsWithID)) {
      setAlerts(alertsWithID);
    }
  }, [alerts]);

  const runner = useRef(() => {});

  // Add axios config
  useEffect(() => {
    axios
        .interceptors
        .response
        .use(
            (response) => {
              if (response.headers['x-jwt-assertion']) {
                setTokenContext(response.headers['x-jwt-assertion']);
              }
              if (response.data && response.data.redirect) {
                window.location.replace(response.data.redirect);
              }
              return response;
            },
            (err) => {
              if ('__CANCEL__' in err) {
                return Promise.reject(err);
              }
              if (err.response && err.response.status === 401) {
                console.log('Got error on request: ', err.request?.responseURL || '', err.toString().substring(0, 300));
                setUser(null);
                setTokenContext('');
              }
              return Promise.reject(err);
            },
        );
  }, [setTokenContext]);

  const refreshAccountFn = useCallback(async () => {
    try {
      const data = await apiCommon.refreshAccount({
        limit: 10,
        filter: JSON.stringify({ readTs: { $eq: null } }),
      });
      setAccount(data);
    } catch (e: any) {
      console.error('Updating account data error: ', e.message);
    }
  }, []);

  // Current app context
  const context = useMemo<AppContext>(() => (new AppContext({
    user,
    setUser: setUserContext,
    loading,
    setLoading,
    token,
    setToken: setTokenContext,
    onTCAccepted: () => false,
    account,
    refreshAccount: refreshAccountFn,
    notificationReadId,
    setNotificationReadId,
    alerts,
    setAlerts,
  })), [user, setUserContext, loading, setLoading, token, setTokenContext,
              account, notificationReadId, refreshAccountFn, alerts]);

  // Check auth when tokenExists is changed
  const checkUser = useCallback(async () => {
    if (!CONFIG.get('CID') && !CONFIG.get('EUID')) {
      try {
        const res = await api.checkAuth();
        // @ts-ignore
        setUser(res?.profile?.user || null);
      } catch (err: any) {
        console.error('Can not authorize through mobius: ', err);
        if (!/\/login\//.test(window.location.href)) {
          if (localStorage.getItem('checkErrorLogBeforeRelogin')) {
            setError(getErrorMsg('Contact the website administrator: ', err));
            return;
          }
          const basename = window.BASENAME || process.env.REACT_APP_BASE_PATH || '';
          window.location.replace(`${window.location.origin}${basename}/login/signin`);
        }
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const { data } = await axios.get(CONFIG.get('api') + '/is-auth-portal');
        if (data.new_token) {
          setTokenContext(data.new_token);
        }
        const res = await api.checkAuth();
        setUser(res?.profile?.user || {
          id: data.customer_id || data.customerId,
          customer: { name: 'TBD: Customer Name' },
        });
        // setUser({
        //   id: data.customer_id || data.customerId,
        //   customer: { name: 'TBD: Customer Name' },
        // });
      } catch (err: any) {
        console.error('Can not authorize through simberry: ', err);
        setError(getErrorMsg('Can not authorize', err));
      } finally {
        setLoading(false);
      }
    }
  }, [setTokenContext]);

  useEffect(() => {
    const loadData = async () => {
      await refreshAccountFn();
      await checkUser();
    };

    runner.current();

    if (token) {
      runner.current = runWithInterval(loadData, 60_000).stop;
    } else {
      checkUser();
    }

    return runner.current;
  }, [checkUser, refreshAccountFn, token]);

  // Google Analytics
  useEffect(() => {
    if (user?.id) {
      initAnalytics(user.id || null);
    }
  }, [user?.id]);

  const loader = (
    <Backdrop
      className="loader-background-color"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <Loading />
    </Backdrop>
  );

  return (
    <AppContextProvider value={context}>
      {loading ? (
          loader
        ) : (
          error
           ? (<Alert severity="error">{error}</Alert>)
           : (
             <Suspense fallback={loader}>
               {user ? (
                 <Router />
               ) : (
                 <Auth />
               )}
             </Suspense>
           )
        )}
    </AppContextProvider>
  );
}
