import {
  Box,
  Typography,
} from '@mui/material';
import React, {
} from 'react';


export type KeywordProps = {
  keyword: string
};

export function Keyword({ keyword }: KeywordProps) {
  return (
    <Box
      sx={{
        borderRadius: '100px',
        border: '1px solid #7C788A',
        pl: 1,
        pr: 1,
      }}
    >
      <Typography
        fontSize="14px"
        fontWeight="400"
        color="#7C788A"
      >
        {keyword}
      </Typography>
    </Box>
  );
}
