import React from 'react';
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { s } from 'i18n';
import { FilterDateRange } from '@xeebi/neru';
import { IconButton, Typography } from '@mui/material';
import DateTime from 'shared-scope/components/DateTime';
import TemplateType from './types';


const iconButtonSx = {
  borderRadius: '4px',
  borderTopRightRadius: '4px !important',
  borderBottomRightRadius: '4px !important',
};

type StructProps<R> = {
  onActionCreate?: (row: R) => void
};

export default function Struct<T>({ onActionCreate }: StructProps<T> = {}) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s('ID'),
      type: 'number',
      filterable: true,
      minWidth: 100,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: 'name',
      headerName: s('Name'),
      filterable: true,
      type: 'string',
      minWidth: 600,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: 'questions',
      headerName: s('Questions'),
      type: 'number',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 0,
      renderCell: ({ value }) => (
        <div>{value > 0 ? value : s('-')}</div>
      ),
    },
    {
      field: 'createTs',
      headerName: s('Created'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params) => (
        <FilterDateRange
          {...params}
          placeholder={s('Start date')}
        />
      ),
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ value }) => <DateTime value={value} direction="row" />,
      minWidth: 150,
      flex: 0,
    },
  ];

  onActionCreate && columns.unshift({
    field: 'action',
    headerName: s('Action'),
    renderCell: ({ row }) => (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onActionCreate(row);
        }}
        aria-label="Create campaign"
        color="primary"
        sx={{
          ...iconButtonSx,
          marginLeft: '0px',
        }}
      >
        <Typography fontSize="20px">
          {row.type === TemplateType.Poll
            ? <i className="icon-send-poll" />
            : null}

          {row.type === TemplateType.Text
            ? <i className="icon-send-text" />
            : null}

          {row.type === TemplateType.OptIn
            ? <i className="icon-send-opt-in" />
            : null}
        </Typography>
      </IconButton>
    ),
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    minWidth: 60,
    flex: 0,
  });


  return columns;
}
