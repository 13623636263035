import { Button } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export default function TagButton({
    children,
    onClick,
    isActive,
    maxWidth = '500px',
}: PropsWithChildren<TagButtonProps>) {
  return (
    <Button
      variant="outlined"
      size="small"
      color="inherit"
      onClick={onClick}
      sx={{
        backgroundColor: (theme) => {
          return isActive ? theme.palette.primary.main : theme.palette.layout.light;
        },
        color: (theme) => {
          return isActive ? theme.palette.layout.light : theme.palette.normal.main;
        },
        '&:hover': {
          color: (theme) => theme.palette.primary.light,
          backgroundColor: (theme) => {
            return isActive ? theme.palette.primary.dark : theme.palette.layout.dark;
          },
        },
        border: 'none',
        fontWeight: 400,
        maxWidth,
      }}
    >
      {children}
    </Button>
  );
}

interface TagButtonProps {
  onClick: () => void
  isActive: boolean
  maxWidth?: string
}
