import { FormProvider, useForm } from 'shared-scope/components/Form';
import React, { useCallback, useEffect } from 'react';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { useNavigate } from 'react-router-dom';
import {
  Actions, StepGeneral, StepGeneralRow, StepTemplate, StepTemplateRow,
} from 'products/shared/Campaign/steps';
import { useRequest } from '@xeebi/neru';
import { fetcher } from 'graphql-api/fetcher';
import useAlert from 'shared-scope/hooks/useAlert';
import { TemplateType } from 'products/shared/Campaign/types';
import { getSdk } from '../query.generated';

const api = getSdk(fetcher);
const back = '/templates';

export default function NewTemplate() {
  const form = useForm<NewTemplateRow>();
  const navigate = useNavigate();
  const { addSuccess, addError } = useAlert();
  const {
    isLoading, error, fetch: createTemplate, result,
  } = useRequest(api.conversationTemplateSave);

  const onSubmit = useCallback(async (row: NewTemplateRow) => {
    const data = {
      name: row.title,
    };

    if (row.message) {
      Object.assign(data, {
        type: TemplateType.Text,
        initialMessage: row.message.text,
        initialAttachment: row.message.attachment,
        initialAnswer: '',
        template: {},
      });
    } else if (row.questions) {
      Object.assign(data, {
        type: TemplateType.Poll,
        ...row.questions,
        initialAttachment: row.questions.initialAttachment.id,
      });
    } else if (row.optIn) {
      Object.assign(data, {
        type: TemplateType.OptIn,
        ...row.optIn,
        initialAttachment: row.optIn.initialAttachment.id,
      });
    }

    await createTemplate({ input: data });
  }, [createTemplate]);

  useEffect(() => {
    error && addError(s('Error creating a new template'));
  }, [error, addError]);

  useEffect(() => {
    result && addSuccess(s('New template added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  return (
    <Page title={s('Create template')} back={back} loading={isLoading}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral titleFieldName={s('Template name')} noRoute />
          </Step>
          <Step label={s('Template')}>
            <StepTemplate />
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create template')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}

type NewTemplateRow = StepGeneralRow & StepTemplateRow;
