import Page from 'layout/Page';
import { s } from 'i18n';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { EditTemplate } from 'products/Templates/EditTemplate';
import { ConversationTemplate } from 'src/graphql-api';
import { ConfirmDlg } from 'shared-scope/components/ConfirmDlg';
import { useNavigate } from 'react-router-dom';
import Struct from 'products/Templates/Struct';
import {
 DataGrid, ApiQueryParams, ApiQueryResult, useGridRef, useMountedState,
} from '@xeebi/neru';
import { getSdk } from 'products/Templates/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import TemplateType from 'products/Templates/types';


const api = getSdk(fetcher);

const loadTemplates = async (params: ApiQueryParams) => {
  const { conversationTemplateCount, conversationTemplate } = await api.getConversationTemplate(params);
  return { count: conversationTemplateCount, rows: conversationTemplate } as ApiQueryResult;
};

export default function Templates() {
  const navigate = useNavigate();
  const gridRef = useGridRef();
  const isMounted = useMountedState();

  const columns = useMemo(() => {
    return Struct<ConversationTemplate>({
      onActionCreate: (row) => {
        const path = row.type === TemplateType.Poll
          ? '/poll/new'
          : row.type === TemplateType.Text
            ? '/campaign/new'
            : '/opt-in/new';
        navigate(`${path}?templateId=${row.id}`);
      },
    });
  }, [navigate]);

  const [template, setTemplate] = useState<ConversationTemplate | null>(null);
  const [templateToDelete, setTemplateToDelete] = useState<ConversationTemplate | null>(null);

  const deleteTemplate = useCallback(async () => {
    if (templateToDelete) {
      await api.conversationTemplateDelete({
        filter: JSON.stringify({ id: templateToDelete.id }),
      });
      gridRef.current.refresh();
      setTemplateToDelete(null);
    }
  }, [templateToDelete, gridRef]);

  const changeTemplate = useCallback(() => {
    isMounted() && gridRef.current.refresh();
    isMounted() && setTemplate(null);
  }, [isMounted, gridRef]);

  return (
    <Page
      title={s('Templates')}
      headTools={[
        <Button
          key="create"
          variant="contained"
          onClick={() => {
            navigate('/templates/new');
          }}
        >
          {s('Add new template')}
        </Button>,
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          storageId="TemplateGridStorage"
          pageSize={15}
          apiRef={gridRef}
          getRows={loadTemplates}
          columns={columns}
          onRowClick={({ row }) => setTemplate(row)}
        />
      </Box>
      {
        templateToDelete ? (
          <ConfirmDlg
            disabled={false}
            confirmParams={{
                content: <div>Are you sure you want to delete template <b>{templateToDelete.name}</b>?</div>,
                show: true,
                onOk: () => deleteTemplate(),
                onClose: () => setTemplateToDelete(null),
            }}
            title={s('Are you sure?')}
            maxWidth="md"
            fullWidth
          />
        ) : null
      }
      {
        template ? (
          <EditTemplate
            template={template}
            onClose={() => setTemplate(null)}
            onDelete={() => {
              isMounted() && setTemplateToDelete(template);
              isMounted() && setTemplate(null);
            }}
            onChange={changeTemplate}
          />
        ) : null
      }

    </Page>
  );
}
