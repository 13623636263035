import * as Types from '../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type CheckAuthQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckAuthQuery = { __typename?: 'Query', profile: { __typename?: 'Profile', user?: { __typename?: 'User', id?: number | null, name?: string | null, email?: string | null, avatar?: string | null, permission?: Array<{ __typename?: 'Permission', id?: string | null, name?: string | null }> | null, customer?: { __typename?: 'Customer', name?: string | null, account?: { __typename?: 'Account', holdAmount?: number | null, postedBalance?: number | null, currency?: { __typename?: 'Currency', id?: string | null, sign?: string | null } | null } | null, profile?: { __typename?: 'AccountProfile', aiEnable?: boolean | null } | null } | null } | null } };

export type LoginQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
}>;


export type LoginQuery = { __typename?: 'Query', login?: string | null };


export const CheckAuthDocument = gql`
    query checkAuth {
  profile {
    user {
      id
      name
      email
      avatar
      permission {
        id
        name
      }
      customer {
        name
        account {
          holdAmount
          postedBalance
          currency {
            id
            sign
          }
        }
        profile {
          aiEnable
        }
      }
    }
  }
}
    `;
export const LoginDocument = gql`
    query login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    checkAuth(variables?: CheckAuthQueryVariables, options?: C): Promise<CheckAuthQuery> {
      return requester<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, variables, options) as Promise<CheckAuthQuery>;
    },
    login(variables: LoginQueryVariables, options?: C): Promise<LoginQuery> {
      return requester<LoginQuery, LoginQueryVariables>(LoginDocument, variables, options) as Promise<LoginQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;