import { Combobox } from '@xeebi/neru';
import { s } from 'i18n';
import Scheduler from 'shared-scope/components/Scheduler';
import { Stack } from '@mui/material';
import React from 'react';
import { FormController } from 'shared-scope/components/Form';
import { FormField } from '../types';
import { DEFAULTS, US_TIME_ZONES } from '../helpers/const';

export default function StepScheduler() {
  return (
    <Stack spacing={3}>
      <FormController
        name={FormField.scheduleTz}
        initial={DEFAULTS.schedule_tz}
        render={({ value, onChange, error }) => (
          <Combobox
            title={s('Time zone')}
            value={US_TIME_ZONES.find((timeZ) => timeZ.k === value) || null}
            options={US_TIME_ZONES}
            onChange={(e, newValue) => onChange(newValue?.k || '')}
            error={!!error}
            helperText={error}
            optionKey="k"
            optionValue="v"
          />
        )}
      />
      <FormController
        name={FormField.scheduleTimes}
        initial={DEFAULTS.schedule_times}
        render={({ value, onChange }) => (
          <Scheduler value={value} onChange={onChange} />
        )}
      />
    </Stack>
  );
}

export type StepSchedulerRow = {
  schedule_tz?: string
  schedule_times?: string
};
