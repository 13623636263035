import { useState, MouseEvent } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import {
  Avatar,
  Grid,
  Icon,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Stack,
} from '@mui/material';
import Number from '@xeebi/neru/dist/react-components/Number';
import pp from 'media/images/paypal.svg';
import MainMenu from 'layout/Menu';
import { useAppContext } from 'shared-scope/AppContext';
import { s } from 'i18n';
import PayPal from 'products/PayPal';
import { PermissionWrapper, PERMISSION_GENERAL } from 'permission';
import * as CONFIG from 'shared-scope/config';
import { BrandFiles, getBrandImg } from 'shared-scope/helpers/functions';
import style from './styles.module.scss';

export default function Header() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openPayPal, setOpenPayPal] = useState(false);

  const appContext = useAppContext();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    appContext.setUser(null);
    appContext.setToken('');
    window.location.reload();
  };

  const avatar = appContext?.user?.avatar || null;
  // Balance
  const postedBalance = appContext?.user?.customer?.account?.postedBalance || 0;
  const holdAmount = appContext?.user?.customer?.account?.holdAmount || 0;
  const { currencySign } = appContext;
  const balance = postedBalance - holdAmount;

  return (
    <Box className={`${style.header}`}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={2}
        wrap="nowrap"
      >
        <Grid item xs="auto" sx={{ display: { xs: 'block', lg: 'none' } }}>
          <NavLink to="/">
            <img
              alt={CONFIG.get('app_name', 'Logo')}
              src={getBrandImg(BrandFiles.LogoMini)}
              height={40}
            />
          </NavLink>
        </Grid>
        <Grid item xs="auto">
          <MainMenu />
        </Grid>
        <Grid item xs="auto" sx={{ display: { xs: 'none', lg: 'block' } }}>
          <NavLink to="/">
            <img
              alt={CONFIG.get('app_name', 'Logo')}
              src={getBrandImg(BrandFiles.LogoFull)}
              height={40}
            />
          </NavLink>
        </Grid>
        <Grid item xs="auto">
          <Grid
            container
            columnSpacing={2}
            justifyContent="flex-end"
            alignItems="center"
            sx={{ paddingBottom: '10px' }}
          >
            <Grid item xs="auto" className={style.balance}>
              <PermissionWrapper scopes={[PERMISSION_GENERAL]}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'inline-block' } }} className="me-2">{s('Balance')}:</Box>
                <Number
                  value={balance}
                  prefix={currencySign || '$'}
                  precision={2}
                  className={`${balance <= 0 ? 'text-danger' : ''}`}
                />
              </PermissionWrapper>
            </Grid>
            <Grid item xs="auto">
              <PermissionWrapper scopes={[PERMISSION_GENERAL]}>
                <div className={`pointer ${style.pp}`} onClick={() => setOpenPayPal(true)}>
                  <img
                    alt="pp"
                    src={pp}
                    height={28}
                  />
                </div>
              </PermissionWrapper>
            </Grid>
            <Grid item xs="auto">
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                {avatar
                  ? (
                    <Avatar
                      alt="Ava"
                      src={avatar}
                      sx={{ width: 46, height: 46 }}
                      onClick={() => navigate('/account-settings')}
                      className="pointer"
                    />)
                  : (
                    <Box
                      className="pointer"
                      sx={{
                        width: '46px',
                        height: '46px',
                        borderRadius: '46px',
                        backgroundColor: '#1E1E3E',
                        paddingLeft: '9px',
                        paddingTop: '9px',
                        display: 'inline-block',
                      }}
                      onClick={() => navigate('/account-settings')}
                    >
                      <Icon className="icon-account" fontSize="medium" />
                    </Box>
                  )}
                <Tooltip title={s('Account settings')}>
                  <Icon
                    onClick={handleClick}
                    className="pointer"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >expand_more</Icon>
                </Tooltip>
              </Stack>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  },
                }}
              >
                <MenuItem onClick={() => navigate('/account-settings')}>
                  <ListItemIcon>
                    <Box component="span" className="icon-users" fontSize={20} />
                  </ListItemIcon>
                  {s('Account settings')}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Box component="span" className="icon-exit" fontSize={20} />
                  </ListItemIcon>
                  {s('Logout')}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PayPal open={openPayPal} onClose={() => setOpenPayPal(false)} />
    </Box>
  );
}
