import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { s } from 'i18n';
import {
  Box,
  Button,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  CampaignETA,
  CampaignStatus,
  CampaignStatuses,
  GridType,
} from 'products/CampaignList/types';
import * as Shared from 'products/CampaignList/helpers';
import campaignStyles from 'products/CampaignList/campaign.module.scss';
import Toolbox from 'products/CampaignList/components/Toolbox';
import useAlert from 'shared-scope/hooks/useAlert';
import Loading from 'shared-scope/components/Loading';
import Terms from 'products/TermsConditions';
import { TermsConditions } from 'graphql-api';
import getActiveTermsConditions from 'products/shared/Campaign/helpers/getActiveTermsConditions';
import {
  ToolKey,
  ToolKeys,
  Tools,
} from './Tools';

export type ActionProps = {
  row: CampaignETA
  onAction: (data: CampaignETA) => void
};

type TCActionParams = {
  tc: TermsConditions
  action: Function
};

export function Action({
  row,
  onAction,
}: ActionProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [tools, setTools] = useState<ToolKeys[]>([]);
  const [text, setText] = useState('');
  const [actionCallback, setActionCallback] = useState<Function | null>(null);
  const { addError } = useAlert();
  const [tc, setTc] = useState<TCActionParams | null>(null);

  const {
    type,
    id: campaignId,
    name,
    status,
    conversationTemplate,
  } = row as CampaignETA;

  const statusId = status?.id as CampaignStatuses;


  useEffect(() => {
    const updateTools: ToolKeys[] = Shared.getToolKeys(statusId, type, !!conversationTemplate?.questions?.length);

    if (updateTools.length > 0) {
      setTools(updateTools);
    }
  }, [statusId, type, conversationTemplate?.questions?.length]);

  const action = useCallback(async (toolAction: Function) => {
    if (campaignId) {
      setLoading(true);
      try {
        const { data } = await toolAction(campaignId);
        if (data?.campaign_id) {
          onAction({
            id: data.campaign_id,
            startTs: data.start_ts,
            stopTs: data.stop_ts,
            status: Shared.getCampaignStatus(data.campaign_status, GridType.Campaign),
          });
        }
      } catch (e: any) {
        setLoading(false);
        console.log(e);
        if (e?.message) {
          addError(e.message);
        }
      }
    }
  }, [onAction, campaignId, addError]);

  useEffect(() => {
    if (statusId === CampaignStatus.Stopping || statusId === CampaignStatus.Starting) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [statusId]);

  const cleanUp = useCallback(() => {
    setText('');
    setActionCallback(null);
  }, []);

  const loadTc = async (tcAction: Function) => {
    const t = await getActiveTermsConditions(row);
    if (t) {
      setTc({ tc: t, action: tcAction });
    } else {
      action(tcAction);
    }
  };

  return <Stack direction="row" justifyContent="start" alignItems="center" sx={{ height: '100%' }}>
    {(campaignId && tools.length > 0)
      ? <Toolbox
          campaignId={campaignId}
          campaignName={name || ''}
          tools={Tools.filter((t) => tools.includes(t.key))}
          onAction={(tAction, confirm, tKey) => {
            if (confirm) {
              setText(confirm);
              setActionCallback(() => tAction);
            } else if (tKey === ToolKey.Start) {
                loadTc(tAction);
              } else {
                action(tAction);
              }
          }}
          loadingSize={20}
          loading={loading}
          toolsStyle={campaignStyles.toolbox}
          short
          buttonStyle={campaignStyles.tool}
      />
      : null}
    {
      tc
      ? <Terms
          tcUser={null}
          tc={tc.tc}
          tcCampaign={row}
          onClose={() => {
            setTc(null);
          }}
          onAccept={() => {
            action(tc.action);
            setTc(null);
          }}
      />
      : <Dialog
          open={(!!text && !!actionCallback)}
          onClose={cleanUp}
          fullWidth
          maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{s('Are you sure?')}</DialogTitle>
        <DialogContent sx={{ paddingTop: '24px !important' }}>
          <Typography>{text}</Typography>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: '100%', p: 2 }}>
            <Stack direction="row" justifyContent="end" spacing={2}>
              <Button
                variant="outlined"
                onClick={cleanUp}
                size="medium"
              >
                {s('Close')}
              </Button>
              {loading
                ? <Loading logo={false} size={40} />
                : (
                  <Button
                    variant="contained"
                    disabled={!actionCallback}
                    onClick={() => {
                      actionCallback && action(actionCallback);
                      cleanUp();
                    }}
                    size="medium"
                  >
                    {s('Ok')}
                  </Button>
                )}
            </Stack>
          </Box>
        </DialogActions>
      </Dialog>
    }
  </Stack>;
}
