import { s } from 'i18n';
import axios, { AxiosResponse } from 'axios';
import * as CONFIG from 'shared-scope/config';
import { ReactElement } from 'react';

export const ToolKey = {
  Stop: 'stop',
  Start: 'start',
  Archive: 'archive',
  Cancel: 'cancel',
  Clone: 'clone',
  CloneNew: 'cloneNew',
  ClonePoll: 'clonePoll',
  ClonePollNew: 'clonePollNew',
} as const;
export type ToolKeys = typeof ToolKey[keyof typeof ToolKey];

export type CampaignEvent = (campaignId: number) => Promise<AxiosResponse>;
export type RedirectPath = (campaignId: number) => string;
export type Confirmation = (campaignName: string) => string;

export type ToolType = {
  handler?: CampaignEvent
  redirect?: RedirectPath | string
  icon: ReactElement
  caption: string
  key: ToolKeys
  confirmation?: Confirmation
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
};

export type ToolsType = ToolType[];

export const START: ToolType = {
  handler: (campaignId) => axios.post(`${CONFIG.get('api')}/campaign/${campaignId}/start`),
  icon: <i className="icon icon-play text-success" />,
  caption: s('Start'),
  key: ToolKey.Start,
  color: 'success',
};

export const STOP: ToolType = {
  handler: (campaignId) => axios.post(`${CONFIG.get('api')}/campaign/${campaignId}/stop`),
  icon: <i className="icon icon-stop text-danger" />,
  caption: s('Stop'),
  key: ToolKey.Stop,
  color: 'error',
};

export const CANCEL: ToolType = {
  handler: (campaignId) => axios.post(`${CONFIG.get('api')}/campaign/${campaignId}/cancel`),
  icon: <i className="icon icon-close text-danger" />,
  caption: s('Cancel'),
  key: ToolKey.Cancel,
  confirmation: (campaignName) => s('Are you sure you want to cancel campaign :campaign?', { campaign: campaignName }),
  color: 'error',
};

export const CLONE: ToolType = {
  redirect: (campaignId) => `/campaign/clone/${campaignId}`,
  icon: <i className="icon icon-copy" title={s('Clone')} />,
  caption: s('Clone'),
  key: ToolKey.Clone,
  color: 'secondary',
};

export const CLONE_NEW: ToolType = {
  redirect: (campaignId) => `/campaign/cloneNew/${campaignId}`,
  icon: <i className="icon icon-copy" title={s('New from Clone')} />,
  caption: s('New from Clone'),
  key: ToolKey.CloneNew,
  color: 'secondary',
};

export const CLONE_POLL: ToolType = {
  redirect: (campaignId) => `/poll/clone/${campaignId}`,
  icon: <i className="icon icon-copy" title={s('Clone')} />,
  caption: s('Clone'),
  key: ToolKey.ClonePoll,
  color: 'secondary',
};

export const CLONE_POLL_NEW: ToolType = {
  redirect: (campaignId) => `/poll/cloneNew/${campaignId}`,
  icon: <i className="icon icon-copy" title={s('New from Clone')} />,
  caption: s('New from Clone'),
  key: ToolKey.ClonePollNew,
  color: 'secondary',
};

export const ARCHIVE: ToolType = {
  handler: (campaignId) => axios.post(`${CONFIG.get('api')}/campaign/${campaignId}/archive`),
  icon: <i className="icon icon-archive text-danger" title={s('Archive')} />,
  caption: s('Archive'),
  key: ToolKey.Archive,
  confirmation: (campaignName) => s('Are you sure you want to archive campaign :campaign?', { campaign: campaignName }),
  color: 'primary',
};

export const Tools: ToolsType = [
  STOP,
  START,
  CANCEL,
  CLONE,
  CLONE_NEW,
  CLONE_POLL,
  CLONE_POLL_NEW,
  ARCHIVE,
];

export const toolMap = {
  [ToolKey.Stop]: STOP,
  [ToolKey.Start]: START,
  [ToolKey.Cancel]: CANCEL,
  [ToolKey.Clone]: CLONE,
  [ToolKey.CloneNew]: CLONE_NEW,
  [ToolKey.ClonePoll]: CLONE_POLL,
  [ToolKey.ClonePollNew]: CLONE_POLL_NEW,
  [ToolKey.Archive]: ARCHIVE,
};
