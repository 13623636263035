import * as CONFIG from 'shared-scope/config';
import axios from 'axios';
import { TZ } from 'shared-scope/const';


export const BLOCK_STYLE = {
  backgroundColor: 'white',
  p: 2,
  borderRadius: 4,
  height: '100%',
};

export async function exportCampaign(campaignId: number, filter: any, reply: boolean) {
  const prefix = reply ? 'replies' : 'messages';
  const baseUrl = reply ? `${CONFIG.get('api')}/export/campaign_replies` : `${CONFIG.get('api')}/export/messages`;
  const statusFilter = reply ? null : JSON.stringify(filter);

  try {
    const prepareResp = await axios.post(`${baseUrl}/prepare`);
    const exportId: string = prepareResp?.data?.exportId;
    if (!exportId) {
      throw new Error('Empty export ID');
    }

    const execResp = await axios.get(
        `${baseUrl}/${campaignId}`,
        {
          responseType: 'blob',
          params: {
            filter: statusFilter,
            exportId,
            tz: TZ,
          },
        },
    );

    if (execResp?.status !== 200) {
      throw new Error(`Wrong status ${execResp?.status}`);
    }

    const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
    const href = URL.createObjectURL(execResp?.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${prefix}_${campaignId}_${fd}.xlsx`);
    link.click();
    URL.revokeObjectURL(href);
  } catch (error) {
    console.log('++export failed++ ', error);
  }
}

export async function exportReport(campaignId: number) {
  try {
    const execResp = await axios.post(
      `${CONFIG.get('graphql_host')}${process.env.REACT_APP_BASE_PATH}/vote/v1/report/${campaignId}`,
      {},
      { responseType: 'blob' },
    );

    if (execResp?.status !== 200) {
      throw new Error(`Wrong status ${execResp?.status}`);
    }

    if (!execResp?.data) {
      throw new Error('Empty report');
    }

    const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
    const href = URL.createObjectURL(execResp.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `report_${campaignId}_${fd}.pdf`);
    link.click();
    URL.revokeObjectURL(execResp.data);
  } catch (error) {
    console.log('++export REPORT failed++ ', error);
  }
}

export const ALL = 'all';
export const NEW = 'new';

export async function exportLeads(campaignId: number, leadType: 'all' | 'new') {
  const filter = leadType === NEW
    ? JSON.stringify({ state: 1 })
    : JSON.stringify({ state: { $in: [1, 2] } });

  try {
    const execResp = await axios.get(
      `${CONFIG.get('api')}/export/conversation/${campaignId}`,
      {
        responseType: 'blob',
        params: {
          filter,
          tz: TZ,
        },
      },
    );

    if (execResp?.status !== 200) {
      throw new Error(`Wrong status ${execResp?.status}`);
    }

    if (!execResp?.data) {
      throw new Error('Empty report');
    }

    const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
    const href = URL.createObjectURL(execResp.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `export_${campaignId}_${leadType}_${fd}.xlsx`);
    link.click();
    URL.revokeObjectURL(execResp.data);
  } catch (error) {
    console.log('++export LEADS failed++ ', error);
  }
}
