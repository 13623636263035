import {
  Box,
  Stack,
  Grid,
  MobileStepper,
  Typography,
  Button,
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { s } from 'i18n';
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  useRequest,
  runWithInterval,
} from '@xeebi/neru';
import { Campaign, GroupQuestion } from 'src/graphql-api';
import { get, isEqual } from 'lodash';
import { CampaignStatus } from 'products/CampaignList/types';
import Loading from 'shared-scope/components/Loading';
import SwipeableViews from 'react-swipeable-views';
import { loadPolls } from 'products/CampaignList/Grid';
import { loadGroupVoteStat } from 'products/Details/Poll/GroupAnswer';
import { UID } from 'shared-scope/helpers/functions';
import { ERROR_GROUP } from 'products/Details/Poll/helpers';
import { colorGenerator } from 'products/Details/Poll/GroupAnswer/Question';
import TagButton from 'shared-scope/components/TagButton';
import PieChart, { PieValue } from 'shared-scope/components/PieChart';
import useAlert from 'shared-scope/hooks/useAlert';


const PIE_GROUP_COUNT = 5;
const OTHER_COLOR = '#b1afbb';

type Item = {
  poll: Campaign
  questions: GroupQuestion[]
};

export default function Charts() {
  const [pollIds, setPollIds] = useState<number[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [item, setItem] = useState<Item | null>(null);
  const [question, setQuestion] = useState<GroupQuestion | null>(null);
  const [pieData, setPieData] = useState<PieValue[]>([]);

  const colors = useRef<{ [key: string]: string }>({});
  const runner = useRef({ stop: () => {} });
  const itemsRef = useRef<{ [key: number]: Item }>({});

  const { error: pollError, fetch: fetchPolls } = useRequest(loadPolls);
  const { error: statError, fetch: fetchStat } = useRequest(loadGroupVoteStat);
  const { addError } = useAlert();

  const [firstLoading, setFirstLoading] = useState(false);

  useEffect(() => {
    if (pollError) {
      addError(pollError.getMessage());
      console.error(pollError.getError());
    }
    if (statError) {
      addError(statError.getMessage());
      console.error(statError.getError());
    }
  }, [pollError, statError, addError]);

  const getPolls = useCallback(async () => {
    !pollIds.length && setFirstLoading(true);
    try {
      const result = await fetchPolls({
        filter: JSON.stringify({ status: CampaignStatus.Complete }),
        limit: 10,
        sort: JSON.stringify([{ id: -1 }]),
      });
      if (result?.rows) {
        /* eslint-disable no-await-in-loop */
        for (const row of result.rows) {
          const statQuestions = await fetchStat(row?.id || -1);
          itemsRef.current[row.id] = {
            poll: row as Campaign,
            questions: statQuestions,
          };
        }
        /* eslint-disable no-await-in-loop */

        const ids = result.rows.map((r: Campaign) => r.id);
        const needUpdate = !isEqual(ids, pollIds);
        if (needUpdate) {
          setPollIds(ids);
          const defaultItem = itemsRef.current[ids[0]] || null;
          setItem((prevItem) => {
            if (!prevItem) {
              return defaultItem;
            }
            return prevItem;
          });
          setQuestion((prevQuestion) => {
            if (!prevQuestion) {
              return defaultItem.questions?.[0] || null;
            }
            return prevQuestion;
          });
        }
      }
    } finally {
      setFirstLoading(false);
    }
  }, [fetchPolls, itemsRef, pollIds, fetchStat]);

  useEffect(() => {
    runner.current.stop();
    runner.current = runWithInterval(getPolls, 60_000);
    return () => runner.current.stop();
  }, [getPolls, runner]);

  useEffect(() => {
    setQuestion(item?.questions?.[0] || null);
    setActiveIndex(pollIds.indexOf(item?.poll?.id || 0));
  }, [item, pollIds]);

  useEffect(() => {
    if (question) {
      const mainGroups = question.groups?.slice(0, PIE_GROUP_COUNT).map((g, id) => {
        let group: string = g?.group || '';
        const pct = g?.count || 0;
        const errorGroup = get(ERROR_GROUP, group || '');
        let color = get(colors.current, id, '');
        if (!color) {
          color = colorGenerator.next().value;
          colors.current[id] = color;
        }
        if (g?.keyword) {
          group = `Keyword: ${group}`;
        }
        return {
          id,
          value: pct,
          label: errorGroup?.label || group,
          color: errorGroup?.color || color,
        } as PieValue;
      }) || [];

      const otherGroups = question.groups?.slice(PIE_GROUP_COUNT, question.groups?.length || 0) || [];
      if (otherGroups.length > 0) {
        const otherValue = {
          id: 'other',
          value: otherGroups.reduce((acc, row) => acc + (row?.count || 0), 0),
          label: s('Other'),
          color: OTHER_COLOR,
        } as PieValue;
        mainGroups.push(otherValue);
      }
      setPieData(mainGroups);
    } else {
      setPieData([]);
    }
  }, [question]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Typography variant="h3">{item?.poll?.name || ''}</Typography>
        <MobileStepper
          variant="dots"
          steps={pollIds.length}
          position="static"
          activeStep={activeIndex}
          sx={{ maxWidth: 300, flexGrow: 1 }}
          nextButton={
            <Button
              size="small"
              onClick={() => {
                const id = pollIds[activeIndex + 1];
                const newItem = itemsRef.current[id];
                setItem(newItem);
              }}
              disabled={activeIndex === (pollIds.length - 1)}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => {
                const id = pollIds[activeIndex - 1];
                const newItem = itemsRef.current[id];
                setItem(newItem);
              }}
              disabled={activeIndex === 0}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </Stack>
      {firstLoading
        ? <Box sx={{ alignSelf: 'center' }}><Loading logo={false} size={160} /></Box>
        : (
          <SwipeableViews
            axis="x"
            index={activeIndex}
            onChangeIndex={(index: number) => {
              const id = pollIds[index];
              const newItem = itemsRef.current[id];
              setItem(newItem);
            }}
            enableMouseEvents
          >
            {pollIds.map((id) => {
              const poolQuestions = itemsRef.current[id]?.questions || [];
              return (
                <Grid key={id} container spacing={2} alignItems="stretch" justifyContent="stretch">
                  <Grid item sm={12} md={6} lg={7}>
                    <Stack
                      spacing={1}
                      direction="row"
                      flexWrap="wrap"
                      useFlexGap
                      alignItems="start"
                      flexShrink={1}
                    >
                      {poolQuestions.map((q) => (
                        <TagButton
                          key={UID()}
                          isActive={q.question === question?.question}
                          onClick={() => setQuestion(q)}
                        >
                          {q.question}
                        </TagButton>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item sm={12} md={6} lg={5}>
                    {question
                      ? (
                        <PieChart height={160} data={pieData} />
                      )
                      : null}
                  </Grid>
                </Grid>
              );
            })}
          </SwipeableViews>
        )}
    </Stack>
  );
}
