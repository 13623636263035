import { Stack, TextField } from '@mui/material';
import { s } from 'i18n';
import { useEffect, useRef } from 'react';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import { RequestErrorCode } from 'shared-scope/RequestError';
import { uploadSource } from '../request';
import { SourceTextValue } from '../types';

const SOURCE_TYPE = 'source-text';

export default function SourceText({
 onChange, disabled, text, onText,
}: SourceTextProps) {
  const { error, fetch } = useRequest(uploadSource);
  const { addError } = useAlert();

  const inputDelay = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const submitChange = async () => {
      if (text) {
        clearTimeout(inputDelay.current);
        inputDelay.current = setTimeout(async () => {
          const result = await fetch({
            source_type: SOURCE_TYPE,
            text,
          });
          if (result?.data) {
            onChange({
              data: result.data,
              source_type: SOURCE_TYPE,
              text,
            });
          }
        }, 500);
      }
    };
    submitChange();
  }, [onChange, text, fetch]);

  useEffect(() => {
    error
    && error.getCode() !== RequestErrorCode.cancelled
    && addError(error.getMessage());
  }, [error, addError]);

  return (
    <Stack spacing={2}>
      <TextField
        label={s('Contacts')}
        value={text}
        onChange={(e) => onText(e.target.value)}
        variant="outlined"
        multiline
        rows={5}
        disabled={disabled}
        required
        helperText={s('Delimiter information with a comma, semi-colon, space or tab')}
      />
    </Stack>
  );
}

type SourceTextProps = {
  onChange: (v: SourceTextValue) => void
  disabled?: boolean
  text: string
  onText: (t: string) => void
};
