import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { s } from 'i18n';
import { get, omit, isEmpty } from 'lodash';
import { FormProvider, useForm } from 'shared-scope/components/Form';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  DEFAULTS,
  FormField,
  request,
  SchedulerLabel,
} from 'products/shared/Campaign';
import {
  Actions,
  StepGeneral,
  StepGeneralRow,
  StepMessage,
  StepMessageRow,
  StepScheduler,
  StepSchedulerRow,
  StepSettings,
  StepSettingsRow,
  StepTarget,
  StepTargetRow,
} from 'products/shared/Campaign/steps';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import { CountryCapability } from 'graphql-api';
import { SourcePhoneBookValue } from 'products/shared/Campaign/types';

const back = '/campaign';

export default function NewCampaign() {
  const form = useForm<NewCampaignRow>();
  const navigate = useNavigate();
  const location = useLocation();
  const { addSuccess, addError } = useAlert();
  const {
   isLoading, error, fetch: createCampaign, result,
  } = useRequest(request.createCampaign);

  const [schedulerOn, setSchedulerOn] = useState(false);

  const templateId = new URLSearchParams(location.search).get('templateId');

  const onSubmit = useCallback(async (row: NewCampaignRow) => {
      await createCampaign(getCampaignData(row, schedulerOn));
  }, [schedulerOn, createCampaign]);

  useEffect(() => {
    error && addError(s('Error creating a new campaign'));
  }, [error, addError]);

  useEffect(() => {
    result && addSuccess(s('New campaign added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  return (
    <Page title={s('Create new campaign')} back={back} loading={isLoading}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral titleFieldName={s('Campaign name')} />
          </Step>
          <Step label={s('Target Audience')}>
            <StepTarget targetType="campaign" />
          </Step>
          <Step label={s('Settings')}>
            <StepSettings
              sourceType={form.getValue(FormField.sourceType)}
              sourceValue={form.getValue(FormField.sourceValue) || null}
            />
          </Step>
          <Step label={s('Compose your message')}>
            <StepMessage
              mapping={form.getValue(FormField.mapping) || {}}
              sourceValue={form.getValue(FormField.sourceValue)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={templateId ? +templateId : null}
            />
          </Step>
          <Step label={<SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler />
              : <Typography variant="subtitle1">{s('Campaign will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create text campaign')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}

export type NewCampaignRow = StepGeneralRow & StepTargetRow & StepSettingsRow & StepMessageRow & StepSchedulerRow;

export function getCampaignData(row: NewCampaignRow, schedulerOn: boolean) {
  let phoneBookFilter = get(row?.source_value, 'phone_book_filter', null);
  phoneBookFilter = isEmpty(phoneBookFilter) ? null : phoneBookFilter;

  const data = {
    ...DEFAULTS,
    ...omit(row.source_value?.data, ['data_lines', 'data_rowset', 'data_sheets', 'file']),
    ...omit(row.source_value, ['data']),
    ...{
      mapping: row.mapping,
      skip: row.mapping_param?.skipTopRows,
      phone_add: row.mapping_param?.addPrefix,
      phone_cut: row.mapping_param?.cutDigits,
      delimiter: row.mapping_param?.delimiter,
      enclosure: row.mapping_param?.quoteCharacter,
      sheets: row.mapping_param?.sheet,
      random: row.mapping_param?.random,
      randomEnabled: !!row.mapping_param?.random,
      msg_templates: [{ text: row.message?.text, enabled: true }],
      campaign_name: row.title,
      country_id: row.route?.country?.id,
      connection_id: row.route?.route?.id,
      conversation_template_id: row.template?.id,
      attachment: row.route?.maxAttachmentSize ? row.message?.attachment : null,
      src_address: row?.number?.number || null,
      src_address_id: row?.number?.number_id || null,
      type: row.type,
      address_book_id: (row.source_value as SourcePhoneBookValue)?.phone_book_id || null,
      phone_book_filter: phoneBookFilter,
      send_to_existing_contacts: row?.send_to_existing_contacts,
    },
  };
  schedulerOn && Object.assign(data, {
    schedule_times: row.schedule_times,
    schedule_tz: row.schedule_tz,
    schedule_enabled: true,
  });
  return data;
}
