import React, { PropsWithChildren, useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { Drawer as MuiDrawer, useTheme } from '@mui/material';
import Loading from 'shared-scope/components/Loading';


export default function Drawer({
  open,
  loading,
  disabled,
  onClose,
  onSave,
  children,
  autoWidth = false,
}: PropsWithChildren<DrawerProps>) {
  const { palette } = useTheme();
  const paperProps = useMemo(() => {
    if (autoWidth) {
      return 'auto';
    }
    return { md: '85%', lg: '75%' };
  }, [autoWidth]);
  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      PaperProps={{ sx: { width: paperProps } }}
    >
      <Stack direction="row" justifyContent="stretch" sx={{ height: '100%' }}>
        <Box
          sx={{
            p: 1,
            pt: 2,
            backgroundColor: 'transparent',
            display: 'flex',
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{
              width: '64px',
              height: '112px',
              backgroundColor: palette.layout.light,
              borderRadius: '8px',
            }}
          >
            {(loading && !onSave)
              ? <Loading logo={false} size={40} />
              : (
                <IconButton
                  key="close"
                  onClick={onClose}
                  sx={{
                    width: '40px',
                    height: '40px',
                    border: '2px solid',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                  }}
                  color="inherit"
                >
                  <i className="icon-close" />
                </IconButton>
              )}

            {(loading && onSave)
              ? <Loading logo={false} size={40} />
              : onSave
                  ? (
                    <IconButton
                      key="save"
                      onClick={onSave}
                      sx={{
                        width: '40px',
                        height: '40px',
                        border: '2px solid',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        '&.Mui-disabled': {
                          backgroundColor: 'white',
                        },
                      }}
                      color="error"
                      disabled={disabled}
                    >
                      <i className="icon-save" />
                    </IconButton>
                  )
                  : null}
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: '#F2F2F6',
            p: 2,
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Stack>
    </MuiDrawer>
  );
}

type DrawerProps = {
  open: boolean
  loading: boolean
  disabled: boolean
  onClose: () => void
  onSave?: () => void
  autoWidth?: boolean
};
