import {
  DataGrid,
  ApiQueryParams,
  ApiQueryResult,
  useGridRef,
  useRequest,
} from '@xeebi/neru';
import { cloneDeep } from 'lodash';
import {
  Stack,
  IconButton,
} from '@mui/material';
import {
  GridRowModesModel,
  GridRowModes,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { UID } from 'shared-scope/helpers/functions';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Region,
  PhoneBook,
  Contact,
} from 'graphql-api';
import useAlert from 'shared-scope/hooks/useAlert';
import Struct from 'products/PhoneBook/PhoneBookGrid/Struct';
import Grid from '@mui/material/Grid';
import { getSdk } from 'products/PhoneBook/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import Imports from 'products/common/CampaignShared/Imports';
import { ImportType } from 'products/common/types';
import { createOrModifyContact, deleteContact, newContact } from './helpers';
import Header from './header';


type PhoneBookGridProps = {
  regions: Region[]
  phoneBook: PhoneBook | null
  onPhoneBookDelete: () => void
};

const api = getSdk(fetcher);

export default function PhoneBookGrid({
    regions,
    phoneBook,
    onPhoneBookDelete,
  }:PhoneBookGridProps) {
  const contactFilter = useRef<string>('null');
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState<Contact[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const gridRef = useGridRef();

  const { addError } = useAlert();

  const {
    error: createError,
    fetch: create,
  } = useRequest(createOrModifyContact);

  const {
    error: deleteError,
    fetch: deleteContacts,
  } = useRequest(deleteContact);

  const deleteRecords = useCallback(async () => {
    await deleteContacts({
      phoneBookId: phoneBook?.id,
      contactIds: selected,
    });
    setSelected([]);
    gridRef.current.refresh();
  }, [deleteContacts, selected, gridRef, phoneBook?.id]);

  const processRowUpdate = useCallback((newRow: any) => {
    const save = async () => {
      const row = cloneDeep(newRow);
      if (row.isNew) {
        delete row.id;
      }
      delete row.isNew;

      await create({
        phoneBookId: phoneBook?.id,
        contact: row,
      });

      gridRef.current.refresh();
    };

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.id]: { mode: GridRowModes.View },
    }));

    phoneBook?.id && save();

    return newRow;
  }, [create, gridRef, phoneBook?.id]);

  const handleSaveClick = useCallback((row) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [row.id]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleCancelClick = useCallback((id) => {
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    gridRef.current.setRows([...rows]);
  }, [rows, gridRef]);

  const columns = useMemo(() => {
    const col = Struct();
    col.push(
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        renderCell: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          return (
            <Stack direction="row">
              {isInEditMode
                ? (
                  <>
                    <IconButton
                      size="small"
                      sx={{
                        color: 'primary.main',
                      }}
                      onClick={() => handleSaveClick(row)}
                    >
                      <i className="icon-save" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleCancelClick(id)}
                    >
                      <i className="icon-close" />
                    </IconButton>
                  </>
                )
                : null}
            </Stack>
          );
        },
      },
    );
    return col;
  }, [handleCancelClick, handleSaveClick, rowModesModel]);

  const loadContacts = useCallback(async (params: ApiQueryParams) => {
    if (phoneBook) {
      contactFilter.current = params.filter;
      const { contactCount, contact } = await api.getContact({ ...params, phoneBookId: phoneBook.id || -1 });
      setCount(contactCount || 0);
      setRows(contact);
      return { count: contactCount, rows: contact } as ApiQueryResult;
    }
    contactFilter.current = 'null';
    return { count: 0, rows: [] } as ApiQueryResult;
  }, [phoneBook]);

  useEffect(() => {
    gridRef.current.refresh();
  }, [loadContacts, gridRef]);

  const addNewRow = useCallback(() => {
    const id = UID();
    const newRow = newContact(id);
    const newRows = [newRow, ...rows];
    gridRef.current.setRows(newRows);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'phoneNumber' },
    }));
  }, [gridRef, rows]);

  useEffect(() => {
    if (createError) {
      addError(createError.getMessage());
      console.error(createError.getError());
    }
    if (deleteError) {
      addError(deleteError.getMessage());
      console.error(deleteError.getError());
    }
  }, [createError, addError, deleteError]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Header phoneBook={phoneBook} onPhoneBookDelete={onPhoneBookDelete} count={count} />
      </Grid>

      {phoneBook?.id && (
        <Grid item xs={12}>
          <Imports type={ImportType.PhoneBook} dstId={phoneBook?.id} title="Imports" statusFilter={false} />
        </Grid>
      )}

      <Grid item xs={12}>
        <DataGrid
          storageId="PhoneBookGridStorage"
          pageSize={30}
          apiRef={gridRef}
          getRows={loadContacts}
          columns={columns}
          processRowUpdate={processRowUpdate}
          editMode="row"
          onCreate={addNewRow}
          createLabel="Add new contact"
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          selected={selected}
          onSelect={setSelected}
          onDelete={deleteRecords}
          checkboxSelection
          hideFooterSelectedRowCount={false}
        />
      </Grid>
    </Grid>
  );
}
