import { useRef } from 'react';
import { isEqual, memoize } from 'lodash';
import * as CONFIG from 'shared-scope/config';

export function isUpperFirst(str: string) {
  return str.charAt(0) === str.charAt(0).toUpperCase();
}

export function getError(xhrError: Record<string, any> | string) {
  if (typeof xhrError === 'string') {
    return xhrError;
  }
  const msg = xhrError.response && xhrError.response.data ? xhrError.response.data.error : '';
  return msg || xhrError.message || xhrError.toString();
}

export function getErrorMsg(prefix: string, xhrError: Record<string, any>) {
  const parts = [];
  const msg = xhrError.response && xhrError.response.data ? (xhrError.response.data.error || xhrError.response.data.message) : '';
  const xhrPart = msg || xhrError.message || xhrError.toString();

  prefix && parts.push(prefix);
  xhrPart && parts.push(xhrPart);
  return parts.join(': ');
}

export function UID() {
  return Math.random().toString(36).substring(2, 34);
}

export function* roundRobin(dataSet: any[]): IterableIterator<string> {
  let index = 0;
  while (true) {
    yield dataSet[index];

    if ((index + 1) === dataSet.length) {
      index = 0;
    } else {
      index += 1;
    }
  }
}

export function* generateId(): IterableIterator<number> {
  let value = 0;
  while (true) {
    yield value;
    value += 1;
  }
}

export function useDeepEqual(obj: any) {
  const prev = useRef(obj);
  // console.log('deepRef: prev.current = ', prev.current);
  const isSame = isEqual(obj, prev.current);
  if (isSame) {
    return prev.current;
  }
  // console.log('deepRef: objects are not equal: ', obj, prev.current);
  prev.current = obj;
  return obj;
}

export function phoneNumberFormat(pn: string) {
  return `${pn.slice(0, 1)} (${pn.slice(1, 4)}) ${pn.slice(4, 7)}-${pn.slice(7, pn.length)}`;
}

export async function copyTextToClipboard(text: string){
  await navigator.clipboard.writeText(text);
}

export enum BrandFiles {
  LogoDark = 'logo_dark.svg',
  LogoFull = 'logo_full.svg',
  LogoMini = 'logo_mini.svg',
  LogoSimple = 'logo_simple.svg',
}
export function getBrandImg(filename: BrandFiles | string) {
    const brandFolder = CONFIG.get('app_brand_folder', '');
    const path = [process.env.REACT_APP_BASE_PATH, 'logo'];
    brandFolder && path.push(brandFolder);
    path.push(filename);
    return path.join('/');
}

function isFileExistsQuery(path: string) {
  const http = new XMLHttpRequest();
  http.open('HEAD', path, false);
  http.send();
  return http.status !== 404;
}
export const isFileExists = memoize(isFileExistsQuery);
