import { createTheme } from '@mui/material';
import { PaletteColorOptions, PaletteColor } from '@mui/material/styles';
import './vars.scss';

type PaletteOptVal = string | number | PaletteColorOptions;

declare module '@mui/material/styles' {
  interface Palette {
    [key: string]: PaletteOptVal | Record<string, PaletteOptVal>
    layout: PaletteColor
    normal: PaletteColor
    muted: PaletteColor
    other: PaletteColor
  }
  interface PaletteOptions {
    [key: string]: PaletteOptVal | Record<string, PaletteOptVal>
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    button: true
    buttonMajor: true
    buttonDlg: true
    buttonDlgFilled: true
  }
}

const COLOR_PRIMARY = '#EF3F08';
const COLOR_PRIMARY_DARK = '#BE1E1E';
const COLOR_HEAD = '#120047';
const COLOR_LAYOUT_LIGHT = '#F6F8FA';
const COLOR_LAYOUT_DARK = '#7C788A';
const COLOR_LAYOUT_MAIN = '#FFFFFF';
const COLOR_PRIMARY_LIGHT = '#FFF2F5';
const COLOR_SECONDARY = '#000020';
const COLOR_MUTED_TEXT = '#7C788A';
const COLOR_PRIMARY_HOVER = '#FEF0EB';

const COLOR_ERROR = '#FF0D2A';
const COLOR_WARNING = '#FFBA34';
const COLOR_INFO = '#094AF0';
const COLOR_SUCCESS = '#01A76F';
const COLOR_TEXT = '#000020';
const COLOR_OTHER = '#7200B1';

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY,
      light: COLOR_PRIMARY_LIGHT,
      dark: COLOR_PRIMARY_DARK,
    },
    error: { main: COLOR_ERROR },
    warning: { main: COLOR_WARNING },
    info: { main: COLOR_INFO },
    success: { main: COLOR_SUCCESS },
    normal: { main: COLOR_TEXT },
    muted: { main: COLOR_MUTED_TEXT },
    other: { main: COLOR_OTHER },
    layout: {
      main: COLOR_LAYOUT_MAIN,
      light: COLOR_LAYOUT_LIGHT,
      dark: COLOR_LAYOUT_DARK,
    },
  },
  typography: {
    fontFamily: '"Rubik", sans-serif',
    fontSize: 16,
    h1: {
      color: COLOR_HEAD,
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '56px',
    },
    h2: {
      color: COLOR_HEAD,
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '32px',
    },
    h3: {
      color: COLOR_HEAD,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '28px',
    },
    h4: {
      color: COLOR_HEAD,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
    },
    h5: {
      color: COLOR_HEAD,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
    },
    subtitle1: {
      color: COLOR_MUTED_TEXT,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
    subtitle2: {
      color: COLOR_MUTED_TEXT,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '19.2px',
    },
  },
});

Object.assign(theme, {
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => {
          const style: any = {};
          if (ownerState.color === 'error') {
            style.backgroundColor = 'transparent';
            style.color = COLOR_ERROR;
            style['&:hover'] = {
              backgroundColor: COLOR_PRIMARY_HOVER,
            };
          } else if (ownerState.color === 'warning') {
            style.backgroundColor = 'transparent';
            style.color = COLOR_WARNING;
            style['&:hover'] = {
              backgroundColor: COLOR_PRIMARY_HOVER,
            };
          } else if (ownerState.color === 'inherit' || ownerState.color === 'secondary') {
            style.backgroundColor = 'transparent';
            style.color = COLOR_SECONDARY;
            style['&:hover'] = {
              backgroundColor: '#ECEEF0',
            };
          } else if (ownerState.color === 'success') {
            style.backgroundColor = 'transparent';
            style.color = COLOR_SUCCESS;
            style['&:hover'] = {
              backgroundColor: '#F6FFFA',
            };
          } else if (ownerState.color === 'primary') {
            style.backgroundColor = 'transparent';
            style.color = COLOR_PRIMARY;
            style['&:hover'] = {
              backgroundColor: COLOR_PRIMARY_HOVER,
            };
          }
          return {
            ...style,
            '&.Mui-disabled': {
              backgroundColor: style.backgroundColor,
              color: style.color,
              opacity: 0.5,
            },
          };
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '24px',
          textTransform: 'none',
          letterSpacing: '1px',
          padding: '12px 24px',
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        sizeSmall: {
          fontSize: '12px',
          padding: '8px 16px',
          borderRadius: '60px',
          lineHeight: '18px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        color: 'white',
        sx: {
          backgroundColor: COLOR_SECONDARY,
          padding: '0.3em 0.8em',
          fontSize: '1.22rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          maxWidth: '600px',
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.layout.main,
          '&.MuiInputBase-multiline': {
            padding: '12px',
            fontSize: '16px',
          },
          input: {
            padding: '12px',
            fontSize: '16px',
          },
        },
        sizeSmall: {
          borderRadius: '8px',
          input: {
            padding: '7px 8px',
            fontSize: '12px',
            minWidth: '170px',
            '&::placeholder': {
              fontSize: '14px',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-5px',
        },
        sizeSmall: {
          fontSize: '16px',
        },
        focused: {
          top: 0,
        },
        filled: {
          top: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '12px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '4px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          marginRight: 5,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: COLOR_LAYOUT_MAIN + ' ' + COLOR_LAYOUT_DARK,
          scrollbarWidth: '0.4em',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: '0.3em',
            height: '0.4em',
            backgroundColor: COLOR_LAYOUT_MAIN,
            cursor: 'pointer',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            minHeight: 24,
            backgroundColor: COLOR_LAYOUT_DARK,
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.layout.dark}`,
          borderRadius: '12px',
          padding: '4px',
          width: 'fit-content',
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            borderRadius: '8px',
            padding: '2px',
          },
        },
        ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '4px 12px',
          borderRadius: '6px !important',
          border: 0,
          '&:not(:last-of-type)': {
            marginRight: '1px',
          },
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          color: theme.palette.muted.main,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#FEF0EB',
          },
          '&.Mui-selected': {
            backgroundColor: '#FEF0EB',
            color: theme.palette.primary.main,
            cursor: 'default',
            '&:hover': {
              backgroundColor: '#FEF0EB',
            },
          },
        },
        sizeSmall: {
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
      },
    },
    MuiMultiInputDateRangeField: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          minHeight: 'auto',
          minWidth: 'auto',
          textTransform: 'none',
          color: theme.palette.layout.dark,
          '&:hover': {
            color: theme.palette.primary.main,
          },
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: 'white',
          '--DataGrid-containerBackground': '#F2F2F6',
          '& .MuiDataGrid-headerFilterRow': {
            '--DataGrid-rowBorderColor': 'none',
          },
        },
      },
    },
  },
});

export default theme;
