import { Stack } from '@mui/material';
import { s } from 'i18n';
import { fetcher } from 'graphql-api/fetcher';
import { useEffect, useRef, useState } from 'react';
import { PhoneBook, PhoneBookFolder } from 'graphql-api';
import { MangoFilter } from 'shared-scope/types';
import { forOwn, isArray, isEmpty } from 'lodash';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest, useMountedState, Combobox } from '@xeebi/neru';
import { getSdk } from '../queries.generated';
import { uploadSource } from '../request';
import { SourcePhoneBookValue, MappingData, ContactListFilter } from '../types';

const api = getSdk(fetcher);
const SOURCE_TYPE = 'source-phone-book';

export default function SourcePhoneBook(
  {
    onChange,
    filter,
    disabled,
    country,
    folder,
    onFolder,
    phonebook,
    onPhonebook,
  }: PhoneBookSourceProps) {
  const isMounted = useMountedState();
  const { addError } = useAlert();
  const {
    isLoading,
    error: errorUpload,
    fetch: fetchUpload,
  } = useRequest(uploadSource);

  const {
    isLoading: loadingFolder,
    error: errorFolder,
    fetch: fetchFolders,
  } = useRequest(api.getPhoneBookFolder);

  const {
    isLoading: loadingPhoneBook,
    error: errorPhoneBook,
    fetch: fetchPhoneBooks,
  } = useRequest(api.getPhoneBook);

  const [uploadedData, setUploadedData] = useState<MappingData | null>(null);

  const [folders, setFolders] = useState<PhoneBookFolder[]>([]);

  const [phonebooks, setPhonebooks] = useState<PhoneBook[]>([]);
  const [dataFilter, setDataFilter] = useState<MangoFilter>({});

  // eslint-disable-next-line
  const uploadRef = useRef((id: number | null) => {});

  useEffect(() => {
    const loadFolders = async () => {
        const { phoneBookFolder } = await fetchFolders();
        isMounted() && setFolders(phoneBookFolder);
    };
    isMounted() && loadFolders();
  }, [isMounted, fetchFolders]);

  /**
   * Make Mango filter
   */
  useEffect(() => {
    if (filter) {
      const filters: MangoFilter = [];
      forOwn(
        filter,
        (f, name) => {
          !isEmpty(f) && filters.push({ [name]: isArray(f) ? { $in: f } : f });
        },
      );
      setDataFilter(filters.length ? { $and: filters } : {});
    } else {
      setDataFilter({});
    }
  }, [filter]);

  /**
   * Initial PB loading
   */
  useEffect(() => {
    const loadPhonebook = async () => {
      const filters: MangoFilter = [];
      filters.push({ 'folder.id': folder ? folder.id : null });
      if (country) {
        filters.push({ 'country.id': country });
      }
      const queryFilter: MangoFilter = filters.length === 1 ? filters[0] : { $and: filters };

      const { phoneBook } = await fetchPhoneBooks({
        filter: JSON.stringify(queryFilter),
        sort: '[{"name": 1}]',
      });
      isMounted() && setPhonebooks(phoneBook);
    };

    isMounted() && loadPhonebook();
  }, [folder, fetchPhoneBooks, isMounted, country]);

  /**
   * send Callback to parent
   */
  useEffect(() => {
    if (uploadedData && phonebook?.id) {
      const folderName = folder?.id ? folder.name : s('No folder');
      onChange({
        data: uploadedData,
        source_type: SOURCE_TYPE,
        campaign_source_type: SOURCE_TYPE,
        campaign_source_name: `${folderName} / ${phonebook.name}`,
        folder_id: folder?.id || null,
        phone_book_id: phonebook.id,
        phone_book_filter: dataFilter,
      });
    }
  }, [
    uploadedData, onChange, folder?.id, phonebook?.id,
    dataFilter, phonebook?.name, folder?.name,
  ]);


  /**
   * Upload phonebook
   */
  useEffect(() => {
    uploadRef.current = async (phoneBookId) => {
      if (!phoneBookId) {
        return;
      }
      const upload = await fetchUpload({
        source_type: SOURCE_TYPE,
        phone_book_id: phoneBookId,
        phone_book_filter: !isEmpty(dataFilter) ? JSON.stringify(dataFilter) : '',
      });
      isMounted() && upload && setUploadedData(upload.data);
    };
  }, [dataFilter, fetchUpload, isMounted]);

  useEffect(() => {
    uploadRef.current(phonebook?.id || null);
  }, [phonebook?.id, dataFilter]);

  useEffect(() => {
    errorUpload && addError(errorUpload.getMessage());
  }, [errorUpload, addError]);

  return <Stack spacing={3}>
    <Combobox<PhoneBookFolder>
      title={s('Folder')}
      options={folders}
      value={folder}
      onChange={(e, v) => {
        onFolder(v);
        onPhonebook(null);
      }}
      optionValue="name"
      optionKey="id"
      loading={loadingFolder}
      disabled={disabled || isLoading || loadingFolder}
      error={!!errorFolder}
      helperText={errorFolder && s('Error loading a folders list')}
    />
    <Combobox<PhoneBook>
      title={s('Phone book')}
      options={phonebooks}
      value={phonebook}
      onChange={(e, v) => onPhonebook(v)}
      optionValue="name"
      optionKey="id"
      loading={loadingPhoneBook}
      disabled={disabled || isLoading || loadingPhoneBook}
      error={!!errorPhoneBook || !!errorUpload}
      helperText={(errorPhoneBook && s('Error loading a phone books list')) || (errorUpload && errorUpload.getMessage())}
    />
  </Stack>;
}

type PhoneBookSourceProps = {
  filter?: ContactListFilter
  onChange: (v: SourcePhoneBookValue) => void
  disabled?: boolean
  country?: number | null
  folder: PhoneBookFolder | null
  onFolder: (folder: PhoneBookFolder | null) => void
  phonebook: PhoneBook | null
  onPhonebook: (pb: PhoneBook | null) => void
};
