import Page from 'layout/Page';
import { s } from 'i18n';
import { Stepper, Step } from 'shared-scope/components/Stepper';
import ActionsBox from 'shared-scope/components/Box/ActionsBox';
import { useNavigate, useParams } from 'react-router-dom';
import { getSdk } from 'products/PhoneBook/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import { PhoneBookFolder } from 'src/graphql-api';
import { useState, useEffect } from 'react';
import {
  Button,
  Stack,
  TextField,
} from '@mui/material';
import { USA } from 'shared-scope/const';
import { Combobox } from '@xeebi/neru';


const api = getSdk(fetcher);

export default function EditPhoneBook() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [folders, setFolders] = useState<PhoneBookFolder[]>([]);
  const [folder, setFolder] = useState<PhoneBookFolder | null>(null);
  const { id } = useParams();
  const phoneBookId = parseInt(id || '-1', 10);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [phoneBook] = (await api.getPhoneBook({
          filter: JSON.stringify({ id: phoneBookId }),
        })).phoneBook;
        const { phoneBookFolder } = await api.getPhoneBookFolder();
        setFolders(phoneBookFolder);
        if (phoneBook) {
          setName(phoneBook.name || '');
          if (phoneBook.folder?.id) {
            for (const f of phoneBookFolder) {
              if (f.id === phoneBook.folder.id) {
                setFolder(f);
                break;
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadData();
  }, [phoneBookId]);

  return (
    <Page title={s('Edit phone book')} back="/phone-book">
      <Stepper>
        <Step label={s('General')}>
          <Stack spacing={2}>
            <TextField
              label={s('Name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              sx={{ width: '600px' }}
              required
            />
            <Combobox<PhoneBookFolder>
              title={s('Folder')}
              options={folders}
              value={folder}
              onChange={(e, v) => setFolder(v)}
              optionValue="name"
              optionKey="id"
              inputParams={{
                InputProps: {
                  autoComplete: 'new-password',
                },
              }}
            />
          </Stack>
        </Step>
      </Stepper>
      <ActionsBox>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={() => navigate(-1)}>{s('Cancel')}</Button>
          <Button variant="contained"
            onClick={() => {
              api.phoneBookSave({
                filter: JSON.stringify({ id: phoneBookId }),
                input: {
                  name,
                  folder: folder?.id,
                  country: USA,
                },
              });
              navigate(-1);
            }}
          >{s('Save changes')}</Button>
        </Stack>
      </ActionsBox>
    </Page>
  );
}
