import * as Types from '../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetTermsConditionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTermsConditionsQuery = { __typename?: 'Query', termsConditionsCount?: number | null, termsConditions: Array<{ __typename?: 'TermsConditions', id?: number | null, version?: string | null, effectiveDate?: any | null, consentDeadline?: any | null, tcText?: string | null, summaryOfChanges?: string | null, acceptTs?: any | null, active?: boolean | null, open?: boolean | null }> };

export type GetTermsConditionsAcceptedQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTermsConditionsAcceptedQuery = { __typename?: 'Query', termsConditionsUserCount?: number | null, termsConditionsUser: Array<{ __typename?: 'TermsConditionsUser', acceptTs?: any | null, termsConditions?: { __typename?: 'TermsConditions', id?: number | null, version?: string | null, effectiveDate?: any | null, consentDeadline?: any | null, tcText?: string | null, summaryOfChanges?: string | null } | null }> };

export type TermsConditionsUserSaveMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  input: Types.TermsConditionsUserInput;
}>;


export type TermsConditionsUserSaveMutation = { __typename?: 'Mutation', termsConditionsUserSave: Array<{ __typename?: 'TermsConditionsUser', id?: number | null } | null> };

export type RefreshAccountQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type RefreshAccountQuery = { __typename?: 'Query', notificationGuiPollingCount?: number | null, notificationGuiPolling: Array<{ __typename?: 'NotificationGuiPolling', readTs?: any | null, notificationGui?: { __typename?: 'NotificationGui', id?: number | null, notificationTs?: any | null, title?: string | null, notifyText?: string | null } | null }> };

export type GetImportsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetImportsQuery = { __typename?: 'Query', import: Array<{ __typename?: 'Import', id?: number | null, status?: number | null, error?: string | null, rowsTotal?: number | null, rowsError?: number | null }> };

export type GetPhoneBookFolderQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetPhoneBookFolderQuery = { __typename?: 'Query', phoneBookFolder: Array<{ __typename?: 'PhoneBookFolder', id?: number | null, name?: string | null }> };

export type GetPhoneBookQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetPhoneBookQuery = { __typename?: 'Query', phoneBook: Array<{ __typename?: 'PhoneBook', id?: number | null, name?: string | null, folder?: { __typename?: 'PhoneBookFolder', id?: number | null } | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null }> };

export type GetImportQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetImportQuery = { __typename?: 'Query', import: Array<{ __typename?: 'Import', id?: number | null, status?: number | null, rowsError?: number | null, rowsInserted?: number | null, rowsSkipped?: number | null, rowsTotal?: number | null, dstId?: number | null }> };

export type ConfirmImportMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ConfirmImportMutation = { __typename?: 'Mutation', confirmImport?: { __typename?: 'confirmImportResult', success?: boolean | null } | null };

export type GetRegionQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetRegionQuery = { __typename?: 'Query', region: Array<{ __typename?: 'Region', name?: string | null, code?: string | null, tz?: string | null }> };

export type GetAttachmentQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetAttachmentQuery = { __typename?: 'Query', attachmentCount?: number | null, attachment: Array<{ __typename?: 'Attachment', id?: string | null, name?: string | null, createTs?: any | null, lastUseTs?: any | null, contentType?: string | null, size?: number | null }> };


export const GetTermsConditionsDocument = gql`
    query getTermsConditions($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  termsConditions(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    version
    effectiveDate
    consentDeadline
    tcText
    summaryOfChanges
    acceptTs
    active
    open
  }
  termsConditionsCount(filter: $filter)
}
    `;
export const GetTermsConditionsAcceptedDocument = gql`
    query getTermsConditionsAccepted($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  termsConditionsUser(
    filter: $filter
    sort: $sort
    limit: $limit
    offset: $offset
  ) {
    termsConditions {
      id
      version
      effectiveDate
      consentDeadline
      tcText
      summaryOfChanges
    }
    acceptTs
  }
  termsConditionsUserCount(filter: $filter)
}
    `;
export const TermsConditionsUserSaveDocument = gql`
    mutation termsConditionsUserSave($filter: String, $input: TermsConditionsUserInput!) {
  termsConditionsUserSave(filter: $filter, input: $input) {
    id
  }
}
    `;
export const RefreshAccountDocument = gql`
    query refreshAccount($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  notificationGuiPolling(
    filter: $filter
    sort: $sort
    limit: $limit
    offset: $offset
  ) {
    notificationGui {
      id
      notificationTs
      title
      notifyText
    }
    readTs
  }
  notificationGuiPollingCount(filter: $filter)
}
    `;
export const GetImportsDocument = gql`
    query getImports($filter: String = null) {
  import(filter: $filter) {
    id
    status
    error
    rowsTotal
    rowsError
  }
}
    `;
export const GetPhoneBookFolderDocument = gql`
    query getPhoneBookFolder($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  phoneBookFolder(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
  }
}
    `;
export const GetPhoneBookDocument = gql`
    query getPhoneBook($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  phoneBook(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
    folder {
      id
    }
    country {
      id
      name
    }
  }
}
    `;
export const GetImportDocument = gql`
    query getImport($filter: String = null, $sort: String = null, $offset: Int = null, $limit: Int = 0) {
  import(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    status
    rowsError
    rowsInserted
    rowsSkipped
    rowsTotal
    dstId
  }
}
    `;
export const ConfirmImportDocument = gql`
    mutation confirmImport($id: Int) {
  confirmImport(id: $id) {
    success
  }
}
    `;
export const GetRegionDocument = gql`
    query getRegion($filter: String = null, $sort: String = null, $offset: Int = null, $limit: Int = 0) {
  region(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    name
    code
    tz
  }
}
    `;
export const GetAttachmentDocument = gql`
    query getAttachment($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  attachment(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
    createTs
    lastUseTs
    contentType
    size
  }
  attachmentCount(filter: $filter)
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getTermsConditions(variables?: GetTermsConditionsQueryVariables, options?: C): Promise<GetTermsConditionsQuery> {
      return requester<GetTermsConditionsQuery, GetTermsConditionsQueryVariables>(GetTermsConditionsDocument, variables, options) as Promise<GetTermsConditionsQuery>;
    },
    getTermsConditionsAccepted(variables?: GetTermsConditionsAcceptedQueryVariables, options?: C): Promise<GetTermsConditionsAcceptedQuery> {
      return requester<GetTermsConditionsAcceptedQuery, GetTermsConditionsAcceptedQueryVariables>(GetTermsConditionsAcceptedDocument, variables, options) as Promise<GetTermsConditionsAcceptedQuery>;
    },
    termsConditionsUserSave(variables: TermsConditionsUserSaveMutationVariables, options?: C): Promise<TermsConditionsUserSaveMutation> {
      return requester<TermsConditionsUserSaveMutation, TermsConditionsUserSaveMutationVariables>(TermsConditionsUserSaveDocument, variables, options) as Promise<TermsConditionsUserSaveMutation>;
    },
    refreshAccount(variables?: RefreshAccountQueryVariables, options?: C): Promise<RefreshAccountQuery> {
      return requester<RefreshAccountQuery, RefreshAccountQueryVariables>(RefreshAccountDocument, variables, options) as Promise<RefreshAccountQuery>;
    },
    getImports(variables?: GetImportsQueryVariables, options?: C): Promise<GetImportsQuery> {
      return requester<GetImportsQuery, GetImportsQueryVariables>(GetImportsDocument, variables, options) as Promise<GetImportsQuery>;
    },
    getPhoneBookFolder(variables?: GetPhoneBookFolderQueryVariables, options?: C): Promise<GetPhoneBookFolderQuery> {
      return requester<GetPhoneBookFolderQuery, GetPhoneBookFolderQueryVariables>(GetPhoneBookFolderDocument, variables, options) as Promise<GetPhoneBookFolderQuery>;
    },
    getPhoneBook(variables?: GetPhoneBookQueryVariables, options?: C): Promise<GetPhoneBookQuery> {
      return requester<GetPhoneBookQuery, GetPhoneBookQueryVariables>(GetPhoneBookDocument, variables, options) as Promise<GetPhoneBookQuery>;
    },
    getImport(variables?: GetImportQueryVariables, options?: C): Promise<GetImportQuery> {
      return requester<GetImportQuery, GetImportQueryVariables>(GetImportDocument, variables, options) as Promise<GetImportQuery>;
    },
    confirmImport(variables?: ConfirmImportMutationVariables, options?: C): Promise<ConfirmImportMutation> {
      return requester<ConfirmImportMutation, ConfirmImportMutationVariables>(ConfirmImportDocument, variables, options) as Promise<ConfirmImportMutation>;
    },
    getRegion(variables?: GetRegionQueryVariables, options?: C): Promise<GetRegionQuery> {
      return requester<GetRegionQuery, GetRegionQueryVariables>(GetRegionDocument, variables, options) as Promise<GetRegionQuery>;
    },
    getAttachment(variables?: GetAttachmentQueryVariables, options?: C): Promise<GetAttachmentQuery> {
      return requester<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, variables, options) as Promise<GetAttachmentQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;