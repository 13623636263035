import {
  Grid, MenuItem, OutlinedInputProps, Select, TextField, Typography,
} from '@mui/material';


export default function MappingParam<T>(
  {
    title,
    type,
    value,
    inputProps,
    inputWidth,
    onChange,
    helperText,
    multiple,
    required = false,
  }: MappingParamProps<T>,
) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} lg={4}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        {
          type && Array.isArray(type)
            ? <Select
                value={value}
                defaultValue={value}
                multiple={multiple}
                onChange={(e) => onChange(e.target.value as T)}
                sx={{ width: inputWidth || 'auto' }}
            >
              {
                type.map((t: any, i) => ((t.constructor === Object)
                  ? <MenuItem value={t.k} key={t.k} sx={{ minHeight: 35 }}>{t.v}</MenuItem>
                  : <MenuItem value={i} key={t} sx={{ minHeight: 35 }}>{t}</MenuItem>),
                )
              }
            </Select>
            : <TextField
                value={value}
                type={type}
                onChange={(e) => onChange(e.target.value as unknown as T)}
                variant="outlined"
                InputProps={inputProps}
                sx={{ width: inputWidth || 'auto' }}
                required={required}
                helperText={helperText}
            />
        }
      </Grid>
    </Grid>
  );
}

export type MappingParamType = {
  k: string
  v: string
};

type MappingParamProps<T> = {
  title: string
  value: T
  type?: MappingParamType[] | string[] | string
  inputProps?: OutlinedInputProps
  inputWidth?: number | string
  onChange: (value: T) => void
  helperText?: string
  multiple?: boolean
  required?: boolean
};


